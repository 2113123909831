import _classCallCheck from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";
export var DecimalNumber = function DecimalNumber() {
  _classCallCheck(this, DecimalNumber);
};

_defineProperty(DecimalNumber, "roundNumber", function (number) {
  var decimalPlaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
});

_defineProperty(DecimalNumber, "roundToInteger", function (number) {
  var decimal = number - Math.floor(number);

  if (decimal >= 0.5) {
    return Math.ceil(number);
  } else {
    return Math.floor(number);
  }
});