import { Action, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  ADD_SECTION_AUDIO_FILE_USE_CASE,
  ADD_SECTION_FILE_USE_CASE,
  AddSectionFileUseCaseInput,
  CANCEL_SECTION_USE_CASE,
  CREATE_SECTION_USE_CASE,
  DELETE_SECTION_AUDIO_FILE_USE_CASE,
  DELETE_SECTION_USE_CASE,
  Questions,
  RequestStatus,
  SAVE_SECTION_USE_CASE,
  Section,
  SectionSummaries,
  Sections,
  UPDATE_SECTION_AUDIO_FILE_USE_CASE,
  UPDATE_SECTION_USE_CASE,
} from '@module/form';
import { FormEditPageState } from 'redux/form-edit/form-edit.states';
import { OPEN_EDIT_SECTION_USE_CASE } from '../../../root.action';
import {
  AddSectionAudioFileUseCaseState,
  AddSectionFileUseCaseState,
  CreateSectionFileUseCaseState,
  CreateSectionUseCaseState,
  DeleteSectionAudioFileUseCaseState,
  DeleteSectionUseCaseState,
  OpenEditSectionUseCaseState,
  SaveSectionUseCaseState,
  UpdateSectionAudioFileUseCaseState,
  UpdateSectionUseCaseState,
} from './section-item.states';
import { CREATE_SECTION_FILE_STORAGE_USE_CASE, CreatedFileStorage } from '@module/file-storage';

export const handleOpenEditSection: UseCaseStateGenerator<OpenEditSectionUseCaseState> = {
  name: OPEN_EDIT_SECTION_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    const sectionId = action.payload;
    const { sections } = state;
    const foundSection = sections.find((s) => s.id.toString() === sectionId);
    const hasUnsavedQuestionOrSectionChanges = state.saveSectionStatus !== RequestStatus.SUCCESS;
    const revertedSection = foundSection.clone();
    return {
      ...state,
      hasUnsavedQuestionOrSectionChanges,
      revertedSection,
      editingSectionId: foundSection.id.toString(),
      selectedFormItemId: foundSection.id.toString(),
      editingFormItemId: foundSection.id.toString(),
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      revertedSection: null,
      editingSectionId: '',
      editingFormItemId: '',
    };
  },
};
// create
export const handleCreateSectionUseCase: UseCaseStateGenerator<CreateSectionUseCaseState> = {
  name: CREATE_SECTION_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      createSectionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action; // new section
    const sections = Sections.insertSectionToSections({
      sections: state.sections,
      addedSection: payload,
    });
    const newSection = Section.cloneTo(payload);
    const sectionSummaries = SectionSummaries.addNewSectionToSectionSummaries(
      state.sectionSummaries,
      newSection,
    );
    const selectedSection = payload as Section;
    return {
      ...state,
      sections,
      sectionSummaries,
      selectedSection,
      hasUnsavedQuestionOrSectionChanges: true,
      createSectionStatus: RequestStatus.SUCCESS,
      selectedFormItemId: selectedSection.id.toString(),
      editingFormItemId: selectedSection.id.toString(),
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      editingFormItemId: '',
      createSectionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      editingFormItemId: '',
      createSectionStatus: RequestStatus.RESET,
    };
  },
};

// update
export const handleUpdateSectionUseCase: UseCaseStateGenerator<UpdateSectionUseCaseState> = {
  name: UPDATE_SECTION_USE_CASE,
  executing: (state: FormEditPageState) => {
    return {
      ...state,
      updateSectionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action) => {
    return {
      ...state,
      sections: Sections.updateSections(state.sections, action.payload),
      updateSectionStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateSectionStatus: RequestStatus.ERROR,
      hasUnsavedQuestionOrSectionChanges: false,
    };
  },
};

// save
export const handleSaveSectionUseCase: UseCaseStateGenerator<SaveSectionUseCaseState> = {
  name: SAVE_SECTION_USE_CASE,
  executing: (state: FormEditPageState) => {
    return {
      ...state,
      saveSectionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action) => {
    const { payload } = action;
    return {
      ...state,
      clonedSections: Sections.updateSections(state.sections, payload),
      sections: Sections.updateSections(state.sections, payload),
      sectionSummaries: SectionSummaries.updateSectionSummaries(state.sectionSummaries, payload),
      saveSectionStatus: RequestStatus.SUCCESS,
      hasUnsavedQuestionOrSectionChanges: false,
      editingFormItemId: '',
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      saveSectionStatus: RequestStatus.ERROR,
      hasUnsavedQuestionOrSectionChanges: false,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      saveSectionStatus: RequestStatus.RESET,
      hasUnsavedQuestionOrSectionChanges: false,
      editingFormItemId: '',
    };
  },
};

// delete
export const handleDeleteSectionUseCase: UseCaseStateGenerator<DeleteSectionUseCaseState> = {
  name: DELETE_SECTION_USE_CASE,
  executing: (state: FormEditPageState, action: Action) => {
    return {
      ...state,
      deleteSectionId: action.payload.sectionId,
      deleteSectionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState) => {
    const deletedIndex = state.sections.findIndex(
      (section) => section.id.toString() === state.deleteSectionId,
    );

    const sections = Sections.deleteSection(state.deleteSectionId, state.sections);
    const sectionSummaries = SectionSummaries.deleteSectionSummary(
      state.deleteSectionId,
      state.sectionSummaries,
    );
    const selectedSection = deletedIndex > 0 ? sections[deletedIndex - 1] : sections[0];
    const updatedQuestions = state.questions.filter((q) => q.sectionId !== state.deleteSectionId);
    const hasEssayOrFillInBlankQuestion =
      updatedQuestions &&
      updatedQuestions.length > 0 &&
      Questions.hasEssayOrFillInBlankQuestion(updatedQuestions);
    return {
      ...state,
      sectionSummaries,
      sections,
      selectedSection,
      hasEssayOrFillInBlankQuestion,
      hasUnsavedQuestionOrSectionChanges: false,
      deleteSectionStatus: RequestStatus.SUCCESS,
      selectedFormItemId: selectedSection.id.toString(),
      editingFormItemId: '',
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteSectionStatus: RequestStatus.ERROR,
    };
  },
};

export const handleCancelSectionStateUseCase: UseCaseStateGenerator<UpdateSectionUseCaseState> = {
  name: CANCEL_SECTION_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      cancelSectionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const updatedSection = payload as Section;

    const { sections } = state;
    const sectionUpdateIndex = sections.findIndex(
      (s) => s.id.toString() === updatedSection.id.toString(),
    );
    sections[sectionUpdateIndex] = updatedSection;
    return {
      ...state,
      sections,
      hasUnsavedQuestionOrSectionChanges: false,
      revertedSection: null,
      cancelSectionStatus: RequestStatus.SUCCESS,
      selectedFormItemId: updatedSection.id.toString(),
      editingFormItemId: '',
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      hasUnsavedQuestionOrSectionChanges: false,
      revertedSection: null,
      cancelSectionStatus: RequestStatus.RESET,
    };
  },
};

export const handleCreateSectionFileUseCase: UseCaseStateGenerator<CreateSectionFileUseCaseState> =
  {
    name: CREATE_SECTION_FILE_STORAGE_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      return {
        ...state,
        uploadingFile: action.payload.file,
        createSectionFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        createSectionFileStatus: RequestStatus.SUCCESS,
        selectedSectionFile: payload as CreatedFileStorage,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error: error,
        createSectionFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        selectedSectionFile: null,
        createQuestionFileStatus: RequestStatus.RESET,
      };
    },
  };

export const handleAddSectionFileUseCase: UseCaseStateGenerator<AddSectionFileUseCaseState> = {
  name: ADD_SECTION_FILE_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    return {
      ...state,
      addSectionFileInput: action.payload as AddSectionFileUseCaseInput,
      addSectionFileStatus: RequestStatus.EXECUTE,
      isSectionEditing: false,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const currentSection = action.payload as Section;
    const updatedSection = state.sections.find(
      (section: Section) => section.id.toString() === currentSection.id.toString(),
    );

    const sections = Sections.updateSections(state.sections, updatedSection);
    return {
      ...state,
      sections,
      addSectionFileStatus: RequestStatus.SUCCESS,
      isSectionEditing: false,
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      addSectionFileStatus: RequestStatus.ERROR,
      isSectionEditing: false,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      selectedSectionFile: null,
      addSectionFileStatus: RequestStatus.RESET,
      isSectionEditing: false,
    };
  },
};

export const handleUpdateSectionAudioFileUseCase: UseCaseStateGenerator<UpdateSectionAudioFileUseCaseState> =
  {
    name: UPDATE_SECTION_AUDIO_FILE_USE_CASE,
    executing: (state: FormEditPageState, action: Action) => {
      return {
        ...state,
        updateSectionAudioFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action) => {
      return {
        ...state,
        updateSectionAudioFileStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error,
        updateSectionAudioFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateSectionAudioFileStatus: RequestStatus.RESET,
      };
    },
  };
export const handleDeleteSectionAudioFileUseCase: UseCaseStateGenerator<DeleteSectionAudioFileUseCaseState> =
  {
    name: DELETE_SECTION_AUDIO_FILE_USE_CASE,
    executing: (state: FormEditPageState, action: Action) => {
      return {
        ...state,
        deleteSectionAudioFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action) => {
      return {
        ...state,
        deleteSectionAudioFileStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error,
        deleteSectionAudioFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        deleteSectionAudioFileStatus: RequestStatus.RESET,
      };
    },
  };
export const handleAddSectionAudioFileUseCase: UseCaseStateGenerator<AddSectionAudioFileUseCaseState> =
  {
    name: ADD_SECTION_AUDIO_FILE_USE_CASE,
    executing: (state: FormEditPageState, action: Action) => {
      return {
        ...state,
        addSectionAudioFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action) => {
      return {
        ...state,
        addSectionAudioFileStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error,
        addSectionAudioFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        addSectionAudioFileStatus: RequestStatus.RESET,
      };
    },
  };
export const sectionItemHandlers = [
  handleCreateSectionUseCase,
  handleUpdateSectionUseCase,
  handleDeleteSectionUseCase,
  handleSaveSectionUseCase,
  handleOpenEditSection,
  handleCancelSectionStateUseCase,
  handleCreateSectionFileUseCase,
  handleAddSectionFileUseCase,
  handleAddSectionAudioFileUseCase,
  handleUpdateSectionAudioFileUseCase,
  handleDeleteSectionAudioFileUseCase,
];
