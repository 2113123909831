import { Entity, Result } from '@digibricks/domains';
import { IBaseCachedRepository } from './base.cached.repository';

export class BaseInMemoryCachedRepository<T extends Entity> implements IBaseCachedRepository<T> {
  protected map: Map<string, T | T[]>;
  constructor() {
    this.map = new Map<string, T | T[]>();
  }

  public async findById(id: string): Promise<Result<T>> {
    return Result.ok(this.map.get(id) as T);
  }

  public async findMany(): Promise<Result<T[]>> {
    const values = Array.from(this.map.values()) as T[];
    return Result.ok(values);
  }

  public async create(domain: T): Promise<Result<T>> {
    if (!this.map.has(domain.id.toString())) {
      this.map.set(domain.id.toString(), domain);
    }

    return Result.ok(domain);
  }

  public async batchCreate(domains: T[]): Promise<Result<T[]>> {
    for (const domain of domains) {
      this.create(domain);
    }
    return Result.ok(domains);
  }

  public async delete(id: string): Promise<Result<boolean>> {
    if (this.map.has(id)) {
      this.map.delete(id);
    }
    return Result.ok(true);
  }

  has(id: string): boolean {
    return this.map.has(id);
  }

  public async removeAll(): Promise<Result<void>> {
    this.map.clear();

    return Result.ok();
  }

  count(): Promise<Result<number>> {
    throw new Error('Method not implemented.');
  }

  update(id: string, domain: T): Promise<Result<T>> {
    throw new Error('Method not implemented.');
  }
}
