import {
  UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
  UpdateExaminationSubmissionUseCase,
  IExaminationSubmissionRepository,
  EXAMINATION_SUBMISSION_REPOSITORY,
} from '@module/assignment';

export const examinationSubmissionUseCaseProviders = [
  {
    provide: UPDATE_EXAMINATION_SUBMISSION_USE_CASE,
    useFactory: (examinationSubmissionRepository: IExaminationSubmissionRepository) => {
      return new UpdateExaminationSubmissionUseCase(examinationSubmissionRepository);
    },
    inject: [EXAMINATION_SUBMISSION_REPOSITORY],
  },
];
