import {
  IQuestionRepository,
  FindQuestionsUseCase,
  QUESTION_REPOSITORY,
  UpdateQuestionUseCase,
  DeleteQuestionUseCase,
  FindQuestionsSummaryUseCase,
  CreateQuestionUseCase,
  FIND_ALL_QUESTIONS_USE_CASE,
  CREATE_QUESTION_USE_CASE,
  DELETE_QUESTION_USE_CASE,
  UPDATE_QUESTION_USE_CASE,
  FIND_QUESTION_SUMMARIES_USE_CASE,
  ADD_QUESTION_FILE_USE_CASE,
  AddQuestionFileUseCase,
  DeleteQuestionFileUseCase,
  DELETE_QUESTION_FILE_USE_CASE,
  FIND_GROUPED_QUESTIONS_BY_SECTION_USE_CASE,
  FindGroupQuestionsBySectionUseCase,
  SAVE_QUESTION_USE_CASE,
  SaveQuestionUseCase,
  CREATE_ANSWER_USE_CASE,
  CreateAnswerUseCase,
  DELETE_ANSWER_USE_CASE,
  DeleteAnswerUseCase,
  UpdateAnswerUseCase,
  UPDATE_QUESTION_ANSWER_USE_CASE,
  UPDATE_CORRECT_ANSWER_USE_CASE,
  UpdateCorrectAnswerUseCase,
  UpdateFillInBlankCorrectAnswerUseCase,
  UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
  UPDATE_QUESTION_TYPE_USE_CASE,
  UpdateQuestionTypeUseCase,
  CANCEL_QUESTION_USE_CASE,
  CancelQuestionUseCase,
  AddAnswerFileUseCase,
  ADD_ANSWER_FILE_USE_CASE,
  UPDATE_QUESTION_AUDIO_FILE_USE_CASE,
  UpdateQuestionAudioFileUseCase,
  DELETE_QUESTION_AUDIO_FILE_USE_CASE,
  DeleteQuestionAudioFileUseCase,
  ADD_QUESTION_AUDIO_FILE_USE_CASE,
  AddQuestionAudioFileUseCase,
  CREATE_SUB_QUESTION_CORRECT_ANSWER_USE_CASE,
  CreateSubQuestionAndCorrectAnswerUseCase,
  UPDATE_SUB_QUESTION_USE_CASE,
  UpdateSubQuestionUseCase,
  UPDATE_MATCHING_CORRECT_ANSWER_USE_CASE,
  UpdateMatchingCorrectAnswerUseCase,
  DELETE_SUB_QUESTION_AND_CORRECT_ANSWER_USE_CASE,
  DeleteSubQuestionAndCorrectAnswerUseCase,
} from '@module/form';
import { Provider } from '@nestjs/common';

export const questionUseCaseProviders: Provider[] = [
  {
    provide: FIND_GROUPED_QUESTIONS_BY_SECTION_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new FindGroupQuestionsBySectionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: FIND_ALL_QUESTIONS_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new FindQuestionsUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: CREATE_QUESTION_USE_CASE,
    useValue: new CreateQuestionUseCase(),
  },
  {
    provide: SAVE_QUESTION_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new SaveQuestionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: UPDATE_QUESTION_TYPE_USE_CASE,
    useValue: new UpdateQuestionTypeUseCase(),
  },
  {
    provide: UPDATE_QUESTION_USE_CASE,
    useValue: new UpdateQuestionUseCase(),
  },
  {
    provide: ADD_QUESTION_FILE_USE_CASE,
    useValue: new AddQuestionFileUseCase(),
  },
  {
    provide: DELETE_QUESTION_FILE_USE_CASE,
    useValue: new DeleteQuestionFileUseCase(),
  },
  {
    provide: DELETE_QUESTION_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new DeleteQuestionUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: FIND_QUESTION_SUMMARIES_USE_CASE,
    useFactory: (questionRepository: IQuestionRepository) => {
      return new FindQuestionsSummaryUseCase(questionRepository);
    },
    inject: [QUESTION_REPOSITORY],
  },
  {
    provide: CREATE_ANSWER_USE_CASE,
    useValue: new CreateAnswerUseCase(),
  },
  {
    provide: DELETE_ANSWER_USE_CASE,
    useValue: new DeleteAnswerUseCase(),
  },
  {
    provide: DELETE_SUB_QUESTION_AND_CORRECT_ANSWER_USE_CASE,
    useValue: new DeleteSubQuestionAndCorrectAnswerUseCase(),
  },
  {
    provide: CREATE_SUB_QUESTION_CORRECT_ANSWER_USE_CASE,
    useValue: new CreateSubQuestionAndCorrectAnswerUseCase(),
  },
  {
    provide: UPDATE_SUB_QUESTION_USE_CASE,
    useValue: new UpdateSubQuestionUseCase(),
  },
  {
    provide: UPDATE_QUESTION_ANSWER_USE_CASE,
    useValue: new UpdateAnswerUseCase(),
  },
  {
    provide: UPDATE_CORRECT_ANSWER_USE_CASE,
    useValue: new UpdateCorrectAnswerUseCase(),
  },
  {
    provide: UPDATE_MATCHING_CORRECT_ANSWER_USE_CASE,
    useValue: new UpdateMatchingCorrectAnswerUseCase(),
  },
  {
    provide: ADD_ANSWER_FILE_USE_CASE,
    useValue: new AddAnswerFileUseCase(),
  },
  {
    provide: UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
    useValue: new UpdateFillInBlankCorrectAnswerUseCase(),
  },
  {
    provide: CANCEL_QUESTION_USE_CASE,
    useValue: new CancelQuestionUseCase(),
  },
  {
    provide: UPDATE_QUESTION_AUDIO_FILE_USE_CASE,
    useValue: new UpdateQuestionAudioFileUseCase(),
  },
  {
    provide: DELETE_QUESTION_AUDIO_FILE_USE_CASE,
    useValue: new DeleteQuestionAudioFileUseCase(),
  },
  {
    provide: ADD_QUESTION_AUDIO_FILE_USE_CASE,
    useValue: new AddQuestionAudioFileUseCase(),
  },
];
