import {
  UpdateFillInBlankCorrectAnswerUseCase,
  UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
} from '@module/form';
import { Provider } from '@nestjs/common';

export const fillInBlankQuestionUseCaseProviders: Provider[] = [
  {
    provide: UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
    useValue: new UpdateFillInBlankCorrectAnswerUseCase(),
  },
];
