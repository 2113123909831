import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';
import { SubmissionPreviewPageStatus } from './submission-preview.reducer';

// Submission
export const submissionSelector = (state: RootReducerState): SubmissionSummary =>
  state.submissionPreviewPage.submission;

export const getSubmissionByIdStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getSubmissionByIdStatus;

// Assignment
export const assignmentSelector = (state: RootReducerState): Assignment<AssignmentProps> =>
  state.submissionPreviewPage.assignment;

export const getAssignmentByIdStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getAssignmentByIdStatus;

// Form Release
export const formReleaseSelector = (state: RootReducerState): FormRelease =>
  state.submissionPreviewPage.formRelease;

export const getFormReleaseByIdStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getFormReleaseByIdStatus;

// Attendee
export const attendeeSelector = (state: RootReducerState): AttendeeDetail =>
  state.submissionPreviewPage.attendeeDetail;

export const getAttendeeByIdStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getAttendeeByIdStatus;

// Question Release
export const questionReleasesSelector = (
  state: RootReducerState,
): QuestionRelease<QuestionReleaseProps>[] => state.submissionPreviewPage.questionReleases;

export const getQuestionReleasesStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getQuestionReleasesStatus;

// Answer
export const submissionAnswersSelector = (
  state: RootReducerState,
): (EssayAnswer | SingleChoiceAnswer | FillInBlankGroupAnswer | MultipleChoiceAnswer)[] =>
  state.submissionPreviewPage.answers;

export const getSubmissionAnswersStatusSelector = (
  state: RootReducerState,
): SubmissionPreviewPageStatus => state.submissionPreviewPage.getAnswersStatus;
