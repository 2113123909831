import { Action, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  FIND_FORM_BY_ID_USE_CASE,
  FIND_GROUPED_QUESTIONS_BY_SECTION_USE_CASE,
  FindGroupedQuestionBySectionUseCaseOutput,
  Form,
  GET_SECTION_SUMMARIES_USE_CASE,
  Section,
  SectionSummary,
} from '@module/form';
import {
  ChangeSelectedSectionUseCaseState,
  FindAllQuestionsUseCaseState,
  FindFormByIdUseCaseState,
  FindSectionSummariesUseCaseState,
  FormPreviewPageState,
  FormPreviewSectionAudios,
} from './form-preview.states';
import {
  UPDATE_FORM_PREVIEW_AUDIO_STATS,
  UPDATE_FORM_PREVIEW_CURRENT_PLAYING_AUDIO,
  UPDATE_IS_AUTO_PLAY_AUDIO_SECTION,
  UPDATE_SELECTED_SECTION,
  UPDATE_TEMPORARY_AUDIO_STATS,
} from '../root.action';

export const handleFindAllQuestionsUseCase: UseCaseStateGenerator<FindAllQuestionsUseCaseState> = {
  name: FIND_GROUPED_QUESTIONS_BY_SECTION_USE_CASE,
  executing: (state: FormPreviewPageState): FormPreviewPageState => {
    return { ...state, isLoadingQuestions: true };
  },
  success: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    const { payload } = action;
    const output = payload as FindGroupedQuestionBySectionUseCaseOutput;
    const { sectionSummaries, questions } = output;
    return {
      ...state,
      questions,
      sectionSummaries,
      isLoadingQuestions: false,
    };
  },
  error: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingQuestions: false,
    };
  },
};

export const handleFindSectionSummariesUseCase: UseCaseStateGenerator<FindSectionSummariesUseCaseState> =
  {
    name: GET_SECTION_SUMMARIES_USE_CASE,
    executing: (state: FormPreviewPageState): FormPreviewPageState => {
      return { ...state, isLoadSectionSummaries: true };
    },
    success: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
      const { payload } = action;
      const sectionSummaries = payload as SectionSummary[];

      return {
        ...state,
        sectionSummaries,
        isLoadSectionSummaries: false,
      };
    },
    error: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        isLoadSectionSummaries: false,
      };
    },
  };

export const handleFindFormByIdUseCase: UseCaseStateGenerator<FindFormByIdUseCaseState> = {
  name: FIND_FORM_BY_ID_USE_CASE,
  executing: (state: FormPreviewPageState): FormPreviewPageState => {
    return { ...state, isLoadingForm: true };
  },
  success: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    const { payload } = action;
    const form = payload as Form;
    const defaultSelectedSectionIndex = 0;

    return {
      ...state,
      form,
      selectedSection: form.sections[defaultSelectedSectionIndex],
      sections: action.payload.sections,
      selectedSectionIndex: defaultSelectedSectionIndex,
      isLoadingForm: false,
    };
  },
  error: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingForm: false,
    };
  },
};

export const handleChangeSelectedSection: UseCaseStateGenerator<ChangeSelectedSectionUseCaseState> =
  {
    name: UPDATE_SELECTED_SECTION,
    executing: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
      const { payload } = action;
      return {
        ...state,
        selectedSection: payload.selectedSection as Section,
        selectedSectionIndex: payload.selectedSectionIndex as number,
      };
    },
  };

export const handleUpdateFormPreviewAudioStats: UseCaseStateGenerator<FormPreviewSectionAudios[]> =
  {
    name: UPDATE_FORM_PREVIEW_AUDIO_STATS,
    executing: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
      const { payload } = action;
      const updatedAudios = state.audioStats ? [...state.audioStats] : [];

      if (!payload?.id) {
        return {
          ...state,
          audioStats: updatedAudios,
        };
      }

      const existingAudioIndex = updatedAudios.findIndex((audio) => audio.id === payload.id);

      if (existingAudioIndex !== -1) {
        updatedAudios[existingAudioIndex] = {
          ...updatedAudios[existingAudioIndex],
          ...payload,
        };
      } else {
        updatedAudios.push(payload);
      }

      return {
        ...state,
        audioStats: updatedAudios,
      };
    },
  };

export const handleUpdateTemporaryAudioStats: UseCaseStateGenerator<FormPreviewSectionAudios[]> = {
  name: UPDATE_TEMPORARY_AUDIO_STATS,
  executing: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    const { payload } = action;
    const updatedTemporaryAudios = state.temporaryAudioStats ? [...state.temporaryAudioStats] : [];

    if (!payload?.id) {
      return {
        ...state,
        temporaryAudioStats: updatedTemporaryAudios,
      };
    }

    const existingTemporaryAudioIndex = updatedTemporaryAudios.findIndex(
      (audio) => audio.id === payload.id,
    );

    let currentNumberOfReplays =
      updatedTemporaryAudios[existingTemporaryAudioIndex]?.numberOfReplays;

    if (currentNumberOfReplays !== undefined) {
      currentNumberOfReplays =
        updatedTemporaryAudios[existingTemporaryAudioIndex]?.numberOfReplays + 1;
    } else {
      currentNumberOfReplays = 0;
    }

    if (existingTemporaryAudioIndex !== -1) {
      updatedTemporaryAudios[existingTemporaryAudioIndex] = {
        ...updatedTemporaryAudios[existingTemporaryAudioIndex],
        ...payload,
        numberOfReplays: currentNumberOfReplays,
      };
    } else {
      updatedTemporaryAudios.push(payload);
    }

    return {
      ...state,
      temporaryAudioStats: updatedTemporaryAudios,
    };
  },
};

export const handleUpdateAutoPlayAudioSection: UseCaseStateGenerator<FormPreviewSectionAudios[]> = {
  name: UPDATE_IS_AUTO_PLAY_AUDIO_SECTION,
  executing: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    return {
      ...state,
      isAutoPlayAudioSection: action.payload,
    };
  },
};

export const handleUpdateCurrentPlayingAudio: UseCaseStateGenerator<FormPreviewSectionAudios[]> = {
  name: UPDATE_FORM_PREVIEW_CURRENT_PLAYING_AUDIO,
  executing: (state: FormPreviewPageState, action: Action): FormPreviewPageState => {
    const { payload } = action;
    return {
      ...state,
      currentPlayingAudio: payload.audioFile,
    };
  },
};

export const formPreviewHandlers = [
  handleFindAllQuestionsUseCase,
  handleFindSectionSummariesUseCase,
  handleFindFormByIdUseCase,
  handleChangeSelectedSection,
  handleUpdateFormPreviewAudioStats,
  handleUpdateTemporaryAudioStats,
  handleUpdateAutoPlayAudioSection,
  handleUpdateCurrentPlayingAudio,
];
