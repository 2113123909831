export const REQUEST_STATUS = {
  DEFAULT: 0,
  EXECUTE: 1,
  SUCCESS: 2,
  FAIL: 3,
  ERROR: 3,
  RESET: 4,
};
export const STATUS_ASSIGNMENT = {
  PROCESSING: '1',
  ACTIVE: '3',
  UPCOMING: '5',
  ONGOING: '6',
  ENDED: '7',
  CANCELED: '2',
};
export const heights = {
  header: '56px',
  pageTitle: '64px',
  tabTitle: '56px',
};
export const FORM_UPDATE_INTERVAL = 20000;
