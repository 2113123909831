import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './root.epic';
import { rootReducer } from './root.reducer';
import { createWrapper } from 'next-redux-wrapper';
import { composeWithDevTools } from '@redux-devtools/extension';

const epicMiddleware = createEpicMiddleware();

export const configureStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);
  return store;
};

export const makeStore = () => configureStore();

export const wrapper = createWrapper(makeStore, { debug: true });
