import { HttpException } from '@nestjs/common';
import {
  BadRequestException,
  HttpStatus,
  UnauthorizedException,
  NotFoundException,
  InternalServerErrorException,
  RequestTimeoutException,
} from '@nestjs/common';

export const ErrorStateHandler = (state: any, error?: Error) => {
  const parsedError = error as HttpException;
  if (error) {
    switch (parsedError.getStatus()) {
      case HttpStatus.BAD_REQUEST:
      default:
        return { ...state, error: parsedError, isLoading: false };
    }
  }
};
