export const UPDATE_SELECTED_SECTION = 'UPDATE_SELECTED_SECTION';
export const OPEN_EDIT_QUESTION_USE_CASE = 'OPEN_EDIT_QUESTION_USE_CASE';
export const EDIT_QUESTION_STATUS_USE_CASE = 'EDIT_QUESTION_STATUS_USE_CASE';
export const SIDEBAR_USE_CASE = 'SIDEBAR_USE_CASE';
export const CHANGE_ACTIVE_SECTION_PANEL_KEY = 'CHANGE_ACTIVE_SECTION_PANEL_KEY';

export const UPDATE_SELECTED_FILE = 'UPDATE_SELECTED_FILE';

export const UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_STATE_USE_CASE =
  'UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_STATE_USE_CASE';
export const UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_STATE_USE_CASE =
  'UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_STATE_USE_CASE';
export const CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_STATE_USE_CASE =
  'CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_STATE_USE_CASE';
export const DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_STATE_USE_CASE =
  'DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_STATE_USE_CASE';
export const DELETE_FILL_IN_BLANK_ANSWER_STATE_USE_CASE =
  'DELETE_FILL_IN_BLANK_ANSWER_STATE_USE_CASE';
export const CREATE_FILL_IN_BLANK_ANSWER_STATE_USE_CASE =
  'CREATE_FILL_IN_BLANK_ANSWER_STATE_USE_CASE';

export const UPDATE_FILL_IN_BLANK_ANSWER_STATE_USE_CASE =
  'UPDATE_FILL_IN_BLANK_ANSWER_STATE_USE_CASE';
export const UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_STATE_USE_CASE =
  'UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_STATE_USE_CASE';

export const SET_QUESTION_IN_VIEW = 'SET_QUESTION_IN_VIEW';
export const OPEN_EDIT_SECTION_USE_CASE = 'OPEN_EDIT_SECTION_USE_CASE';

export const SELECT_ITEM_IN_FORM_EDIT = 'SELECT_ITEM_IN_FORM_EDIT';
export const OPEN_EDIT_ITEM_IN_FORM_EDIT = 'OPEN_EDIT_ITEM_IN_FORM_EDIT';

// Audio
export const UPDATE_FORM_PREVIEW_AUDIO_STATS = 'UPDATE_FORM_PREVIEW_AUDIO_STATS';
export const UPDATE_TEMPORARY_AUDIO_STATS = 'UPDATE_TEMPORARY_AUDIO_STATS';
export const UPDATE_IS_AUTO_PLAY_AUDIO_SECTION = 'UPDATE_IS_AUTO_PLAY_AUDIO_SECTION';
export const UPDATE_FORM_PREVIEW_CURRENT_PLAYING_AUDIO =
  'UPDATE_FORM_PREVIEW_CURRENT_PLAYING_AUDIO';
