import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  CREATE_ANSWER_USE_CASE,
  CreateAnswerUseCaseInput,
  Question,
  QuestionProps,
  Questions,
  UPDATE_QUESTION_ANSWER_USE_CASE,
  UPDATE_CORRECT_ANSWER_USE_CASE,
  UpdateAnswerUseCaseInput,
  UpdateCorrectAnswerUseCaseInput,
  UpdateFillInBlankCorrectAnswerUseCaseInput,
  DELETE_ANSWER_USE_CASE,
  QuestionType,
  SectionSummary,
  UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
  FillInBlankQuestion,
  CreateSubQuestionAndCorrectAnswerUseCaseInput,
  CREATE_SUB_QUESTION_CORRECT_ANSWER_USE_CASE,
  UPDATE_SUB_QUESTION_USE_CASE,
  UpdateSubQuestionUseCaseInput,
  UpdateMatchingCorrectAnswerUseCaseInput,
  UPDATE_MATCHING_CORRECT_ANSWER_USE_CASE,
  DELETE_SUB_QUESTION_AND_CORRECT_ANSWER_USE_CASE,
} from '@module/form';
import {
  CreateAnswerUseCaseState,
  CreateSubQuestionAndCorrectAnswerUseCaseState,
  DeleteAnswerUseCaseState,
  DeleteSubQuestionAndCorrectAnswerUseCaseState,
  UpdateAnswerUseCaseState,
  UpdateCorrectAnswerUseCaseState,
  UpdateFillInBlankCorrectAnswerUseCaseState,
  UpdateMatchingCorrectAnswerUseCaseState,
  UpdateSubQuestionUseCaseState,
} from './answer-item.states';
import { FormEditPageState } from 'redux/form-edit';

// create
export const handleCreateAnswerStateUseCase: UseCaseStateGenerator<CreateAnswerUseCaseState> = {
  name: CREATE_ANSWER_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const newAnswerInput = payload as CreateAnswerUseCaseInput;

    return {
      ...state,
      newAnswerInput,
      createAnswerStatus: RequestStatus.EXECUTE,
      updateQuestionOrAnswerStatus: RequestStatus.EXECUTE,
      isQuestionEditing: true,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const { questions } = state;
    const updatedQuestion = payload as Question<QuestionProps>;
    const questionUpdateIndex = questions.findIndex(
      (q) => q.id.toString() === updatedQuestion.id.toString(),
    );
    questions[questionUpdateIndex] = updatedQuestion;

    if (updatedQuestion.type === QuestionType.FillInBlank) {
      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        questions,
      );

      return {
        ...state,
        questions: Questions.addIndex(questions),
        createAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
        sectionSummaries,
      };
    }
    return {
      ...state,
      questions: Questions.addIndex(questions),
      createAnswerStatus: RequestStatus.SUCCESS,
      updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      isQuestionEditing: true,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      createAnswerStatus: RequestStatus.RESET,
      isQuestionEditing: false,
    };
  },
};

export const handleCreateSubQuestionAndCorrectAnswerStateUseCase: UseCaseStateGenerator<CreateSubQuestionAndCorrectAnswerUseCaseState> =
  {
    name: CREATE_SUB_QUESTION_CORRECT_ANSWER_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const newSubQuestionInput = payload as CreateSubQuestionAndCorrectAnswerUseCaseInput;

      return {
        ...state,
        newSubQuestionInput,
        createSubQuestionAndCorrectAnswerStatus: RequestStatus.EXECUTE,
        updateQuestionOrAnswerStatus: RequestStatus.EXECUTE,
        isQuestionEditing: true,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const { questions } = state;
      const updatedQuestion = payload as Question<QuestionProps>;
      const questionUpdateIndex = questions.findIndex(
        (q) => q.id.toString() === updatedQuestion.id.toString(),
      );
      questions[questionUpdateIndex] = updatedQuestion;

      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        questions,
      );

      return {
        ...state,
        questions: Questions.addIndex(questions),
        createSubQuestionAndCorrectAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
        sectionSummaries,
        isQuestionEditing: true,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAnswerStatus: RequestStatus.RESET,
        isQuestionEditing: false,
      };
    },
  };
// update
export const handleUpdateSubQuestionUseCaseState: UseCaseStateGenerator<UpdateSubQuestionUseCaseState> =
  {
    name: UPDATE_SUB_QUESTION_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const updateSubQuestionInput = payload as UpdateSubQuestionUseCaseInput;
      return {
        ...state,
        updateSubQuestionInput,
        updateSubQuestionStatus: RequestStatus.EXECUTE,
        updateQuestionOrAnswerStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const { questions } = state;
      const updatedQuestion = payload as Question<QuestionProps>;
      const questionUpdateIndex = questions.findIndex(
        (q) => q.id.toString() === updatedQuestion.id.toString(),
      );
      questions[questionUpdateIndex] = updatedQuestion;

      return {
        ...state,
        isQuestionEditing: true,
        questions,
        updateSubQuestionStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateSubQuestionStatus: RequestStatus.RESET,
        updateQuestionOrAnswerStatus: RequestStatus.RESET,
      };
    },
  };
export const handleUpdateAnswerUseCaseState: UseCaseStateGenerator<UpdateAnswerUseCaseState> = {
  name: UPDATE_QUESTION_ANSWER_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const updateAnswerInput = payload as UpdateAnswerUseCaseInput;
    return {
      ...state,
      updateAnswerInput,
      updateAnswerStatus: RequestStatus.EXECUTE,
      updateQuestionOrAnswerStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const { questions } = state;
    const updatedQuestion = payload as Question<QuestionProps>;
    const questionUpdateIndex = questions.findIndex(
      (q) => q.id.toString() === updatedQuestion.id.toString(),
    );
    questions[questionUpdateIndex] = updatedQuestion;

    return {
      ...state,
      isQuestionEditing: true,
      questions,
      updateAnswerStatus: RequestStatus.SUCCESS,
      updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      updateAnswerStatus: RequestStatus.RESET,
      updateQuestionOrAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdateFillInBlankCorrectAnswerState: UseCaseStateGenerator<UpdateFillInBlankCorrectAnswerUseCaseState> =
  {
    name: UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const updateFillInBlankCorrectAnswerInput =
        payload as UpdateFillInBlankCorrectAnswerUseCaseInput;

      return {
        ...state,
        updateFillInBlankCorrectAnswerStatus: RequestStatus.EXECUTE,
        updateFillInBlankCorrectAnswerInput,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const { questions } = state;

      const updatedQuestion = payload as FillInBlankQuestion;
      const questionUpdateIndex = questions.findIndex(
        (q) => q.id.toString() === updatedQuestion.id.toString(),
      );
      questions[questionUpdateIndex] = updatedQuestion;

      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        questions,
      );

      return {
        ...state,
        questions,
        sectionSummaries,
        updateFillInBlankCorrectAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateFillInBlankCorrectAnswerStatus: RequestStatus.RESET,
      };
    },
  };

export const handleUpdateCorrectAnswerState: UseCaseStateGenerator<UpdateCorrectAnswerUseCaseState> =
  {
    name: UPDATE_CORRECT_ANSWER_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const updateCorrectAnswerInput = payload as UpdateCorrectAnswerUseCaseInput;
      return {
        ...state,
        updateCorrectAnswerInput,
        updateCorrectAnswerStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const { questions } = state;
      const updatedQuestion = payload as Question<QuestionProps>;
      const questionUpdateIndex = questions.findIndex(
        (q) => q.id.toString() === updatedQuestion.id.toString(),
      );
      questions[questionUpdateIndex] = updatedQuestion;

      return {
        ...state,
        questions,
        updateCorrectAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
        isQuestionEditing: true,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateCorrectAnswerStatus: RequestStatus.RESET,
        updateQuestionOrAnswerStatus: RequestStatus.RESET,
      };
    },
  };

export const handleUpdateMatchingCorrectAnswerUseCaseState: UseCaseStateGenerator<UpdateMatchingCorrectAnswerUseCaseState> =
  {
    name: UPDATE_MATCHING_CORRECT_ANSWER_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const updateMatchingCorrectAnswerInput = payload as UpdateMatchingCorrectAnswerUseCaseInput;
      return {
        ...state,
        updateMatchingCorrectAnswerInput,
        updateMatchingCorrectAnswerStatus: RequestStatus.EXECUTE,
        updateQuestionOrAnswerStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const { questions } = state;
      const updatedQuestion = payload as Question<QuestionProps>;
      const questionUpdateIndex = questions.findIndex(
        (q) => q.id.toString() === updatedQuestion.id.toString(),
      );
      questions[questionUpdateIndex] = updatedQuestion;
      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        questions,
      );
      return {
        ...state,
        questions,
        sectionSummaries,
        updateMatchingCorrectAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateMatchingCorrectAnswerStatus: RequestStatus.RESET,
        updateQuestionOrAnswerStatus: RequestStatus.RESET,
      };
    },
  };

export const handleUpdateFillInBlankCorrectAnswerUseCaseState: UseCaseStateGenerator<UpdateFillInBlankCorrectAnswerUseCaseState> =
  {
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const updateFillInBlankCorrectAnswerInput =
        payload as UpdateFillInBlankCorrectAnswerUseCaseInput;
      return {
        ...state,
        updateFillInBlankCorrectAnswerInput,
        updateFillInBlankCorrectAnswerStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const updatedQuestion = payload as Question<QuestionProps>;
      const questions = state.questions.filter(
        (q) => q.id.toString() !== updatedQuestion.id.toString(),
      );
      questions.push(updatedQuestion);
      const sortedQuestions = Questions.sortQuestionsWithIndexAndSection(questions);
      return {
        ...state,
        questions: sortedQuestions,
        updateFillInBlankCorrectAnswerStatus: RequestStatus.SUCCESS,
        isQuestionEditing: true,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateFillInBlankCorrectAnswerStatus: RequestStatus.RESET,
      };
    },
  };

// delete
export const handleDeleteSubQuestionAndCorrectAnswerUseCaseState: UseCaseStateGenerator<DeleteSubQuestionAndCorrectAnswerUseCaseState> =
  {
    name: DELETE_SUB_QUESTION_AND_CORRECT_ANSWER_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        deleteSubQuestionAndCorrectAnswerStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const { questions } = state;
      const updatedQuestion = payload as Question<QuestionProps>;
      const questionUpdateIndex = questions.findIndex(
        (q) => q.id.toString() === updatedQuestion.id.toString(),
      );
      questions[questionUpdateIndex] = updatedQuestion;
      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        questions,
      );
      return {
        ...state,
        questions: Questions.addIndex(state.questions),
        sectionSummaries,
        deleteSubQuestionAndCorrectAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        deleteSubQuestionAndCorrectAnswerStatus: RequestStatus.RESET,
      };
    },
  };

export const handleDeleteAnswerUseCaseState: UseCaseStateGenerator<DeleteAnswerUseCaseState> = {
  name: DELETE_ANSWER_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      deleteAnswerStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const { questions } = state;
    const updatedQuestion = payload as Question<QuestionProps>;
    const questionUpdateIndex = questions.findIndex(
      (q) => q.id.toString() === updatedQuestion.id.toString(),
    );
    questions[questionUpdateIndex] = updatedQuestion;
    if (updatedQuestion.type === QuestionType.FillInBlank) {
      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        questions,
      );

      return {
        ...state,
        questions: Questions.addIndex(questions),
        deleteAnswerStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
        sectionSummaries,
      };
    }
    return {
      ...state,
      questions: Questions.addIndex(state.questions),
      deleteAnswerStatus: RequestStatus.SUCCESS,
      updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      deleteAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const answerItemHandlers = [
  handleCreateAnswerStateUseCase,
  handleUpdateAnswerUseCaseState,
  handleDeleteAnswerUseCaseState,
  handleUpdateAnswerUseCaseState,
  handleUpdateCorrectAnswerState,
  handleUpdateFillInBlankCorrectAnswerState,
  handleCreateSubQuestionAndCorrectAnswerStateUseCase,
  handleUpdateSubQuestionUseCaseState,
  handleDeleteSubQuestionAndCorrectAnswerUseCaseState,
  handleUpdateMatchingCorrectAnswerUseCaseState,
];
