import {
  AnswerCommentUseCaseInput,
  AnswerCommentUseCaseOutput,
  AnswerType,
  Attendee,
  AttendeeSubmission,
  DecimalNumber,
  EssayAnswer,
  ExaminationAssignment,
  FillInBlankGroupAnswer,
  GradingAnswerUseCaseInput,
  GradingAnswerUseCaseOutput,
  GradingSubmissionUseCaseOutput,
  HomeworkAssignment,
  MatchingAnswerGroup,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  UpdateSubmissionUseCaseOutput,
} from '@module/assignment';
import { GradingSubmissionState, GradingSubmissionStatus } from './grading-submission.reducer';
import { Action, RequestStatus } from '@lib/plugin-redux-core';
import { FormRelease, QuestionRelease, QuestionReleaseProps, QuestionReleases } from '@module/form';

const DECIMAL_PLACES = 3;
const calculateTotalScore = (
  answers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[],
): number =>
  answers.reduce((totalScore, item) => {
    const isFillInBlankAnswer = item.type === AnswerType.FillInBlank;
    if (isFillInBlankAnswer) {
      const fillInBlankAnswer = item as FillInBlankGroupAnswer;

      let fillInBlankAnswerScore = 0;
      fillInBlankAnswer.answers.forEach((answer) => {
        if (answer.hasGraded()) {
          fillInBlankAnswerScore = fillInBlankAnswerScore + answer.score;
        }
      });

      return DecimalNumber.roundNumber(totalScore + fillInBlankAnswerScore, DECIMAL_PLACES);
    }

    const answer = item as SingleChoiceAnswer | MultipleChoiceAnswer | EssayAnswer;
    if (!answer.hasGraded()) {
      return totalScore;
    }
    return DecimalNumber.roundNumber(totalScore + answer.score, DECIMAL_PLACES);
  }, 0);

const calculateMaxTotalScore = (
  questionReleases: QuestionRelease<QuestionReleaseProps>[],
): number =>
  questionReleases.reduce((totalMaxScore, item) => {
    return totalMaxScore + item.score;
  }, 0);

const updateAnswers = (
  answers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[],
  answer:
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup,

  attendeeList: Attendee[],
  attendee?: Attendee,
): {
  updatedAnswers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[];
  updatedAttendeeList: Attendee[];
} => {
  const updatedAnswerIndex = answers.findIndex((item) => item.questionId === answer.questionId);
  answers[updatedAnswerIndex] = answer;

  if (attendee) {
    const updatedAttendeeIndex = attendeeList.findIndex(
      (item) => item.id.toString() === attendee.id.toString(),
    );
    attendeeList[updatedAttendeeIndex] = attendee;
  }

  return {
    updatedAnswers: answers,
    updatedAttendeeList: attendeeList,
  };
};

const updateAttendeeList = (attendeeList: Attendee[], attendee: Attendee): Attendee[] => {
  return attendeeList.map((attendeeElement) => {
    if (attendeeElement.id.toString() === attendee.id.toString()) {
      return attendee;
    }
    return attendeeElement;
  });
};

export const handleGetAttendeesUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAttendeeListStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const attendeeList = action.payload as Attendee[];

    return {
      ...state,
      attendeeList: attendeeList,
      getAttendeeListStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getAttendeeListStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      attendeeList: null,
      getAttendeeListStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGetAttendeeByIdUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAttendeeByIdStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    return {
      ...state,
      attendee: action.payload as Attendee,
      getAttendeeByIdStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getAttendeeByIdStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      attendee: null,
      getAttendeeByIdStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGetFormReleaseByIdUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getFormReleaseByIdStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { payload } = action;

    return {
      ...state,
      formRelease: payload as FormRelease,
      getFormReleaseByIdStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error,
      getFormReleaseByIdStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getFormReleaseByIdStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGetAssignmentByIdUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAssignmentByIdStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { payload } = action;

    const assignment = payload as HomeworkAssignment | ExaminationAssignment;

    return {
      ...state,
      assignment,
      getAssignmentByIdStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      getAssignmentByIdStatus: GradingSubmissionStatus.ERROR,
      error,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAssignmentByIdStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGetSubmissionById = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getSubmissionByIdStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { payload } = action;
    return {
      ...state,
      submission: payload,
      getSubmissionByIdStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      getSubmissionByIdStatus: GradingSubmissionStatus.ERROR,
      error,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getSubmissionByIdStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGetQuestionReleasesUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getQuestionReleasesStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const questionReleases = action.payload as QuestionRelease<QuestionReleaseProps>[];
    const sortedQuestions = QuestionReleases.sortQuestions(questionReleases);

    const maxScore = calculateMaxTotalScore(questionReleases);
    return {
      ...state,
      questionReleases: sortedQuestions,
      maxScore,
      getQuestionReleasesStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      getQuestionReleasesStatus: GradingSubmissionStatus.ERROR,
      error,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getQuestionReleasesStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGetAnswersUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAnswersStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const answers = action.payload as (
      | EssayAnswer
      | SingleChoiceAnswer
      | FillInBlankGroupAnswer
      | MultipleChoiceAnswer
      | MatchingAnswerGroup
    )[];

    const totalScore = calculateTotalScore(answers);

    return {
      ...state,
      answers,
      totalScore,
      getAnswersStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAnswersStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAnswersStatus: GradingSubmissionStatus.RESET,
      answers: null,
      totalScore: null,
    };
  },
};

export const handleGetAttendeeSubmissionUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAttendeeSubmissionStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const attendeeSubmission = action.payload as AttendeeSubmission;
    return {
      ...state,
      attendeeSubmission,
      submission: attendeeSubmission?.submissions[0],
      getAttendeeSubmissionStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getAttendeeSubmissionStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      attendeeSubmission: null,
      submission: null,
      getAttendeeSubmissionStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleUpdateAttendeeGradedStateUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateAttendeeGradedStateStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const updatedAttendee = action.payload as Attendee;

    const foundUpdatedAttendeeIndex = state.attendeeList.findIndex(
      (attendee) => attendee.id.toString() === updatedAttendee.id.toString(),
    );

    state.attendeeList.splice(foundUpdatedAttendeeIndex, 1, updatedAttendee);

    return {
      ...state,
      attendeeList: state.attendeeList,
      updateAttendeeGradedStateStatus: GradingSubmissionStatus.SUCCESS,
    };
  },
};

export const handleGradingAnswersUseCase = {
  executing: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const useCaseInput = action.payload as GradingAnswerUseCaseInput;
    const { id } = useCaseInput;

    return {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
      updatedAnswerId: id,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const { updatedAnswer, updatedAttendee } = action.payload as GradingAnswerUseCaseOutput;

    const { updatedAnswers, updatedAttendeeList } = updateAnswers(
      answers,
      updatedAnswer,
      attendeeList,
      updatedAttendee,
    );
    const totalScore = calculateTotalScore(updatedAnswers);

    return {
      ...state,
      updateAnswerStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
      totalScore,
      attendeeList: updatedAttendeeList,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateAnswerStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateAnswerStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const handleAnswerCommentUseCase = {
  executing: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const useCaseInput = action.payload as AnswerCommentUseCaseInput;
    const { id } = useCaseInput;

    return {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
      updatedAnswerId: id,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const { updatedAnswer } = action.payload as AnswerCommentUseCaseOutput;

    const { updatedAnswers } = updateAnswers(answers, updatedAnswer, attendeeList);

    return {
      ...state,
      updateAnswerStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateAnswerStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateAnswerStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const handleUpdateSubmissionUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateSubmissionStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { submission } = action.payload as UpdateSubmissionUseCaseOutput;
    return {
      ...state,
      updateSubmissionStatus: GradingSubmissionStatus.SUCCESS,
      submission,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateSubmissionStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateSubmissionStatus: GradingSubmissionStatus.RESET,
    };
  },
};

export const handleGradingSubmissionUseCase = {
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      gradingSubmissionStatus: GradingSubmissionStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const payload = action.payload as GradingSubmissionUseCaseOutput;

    const { submission, attendee } = payload;
    const updatedAttendeeList = updateAttendeeList(state.attendeeList, attendee);

    return {
      ...state,
      gradingSubmissionStatus: GradingSubmissionStatus.SUCCESS,
      submission: submission,
      attendeeList: updatedAttendeeList,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      gradingSubmissionStatus: GradingSubmissionStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      gradingSubmissionStatus: GradingSubmissionStatus.RESET,
    };
  },
};
