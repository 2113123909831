import {
  ANSWER_COMMENT_USE_CASE,
  AnswerCommentUseCase,
  GRADING_ANSWER_USE_CASE,
  GradingAnswerUseCase,
  ISubmissionRepository,
  SUBMISSION_REPOSITORY,
  UPDATE_ESSAY_ANSWER_USE_CASE,
  UPDATE_SUBMISSION_USE_CASE,
  UpdateEssayAnswerUseCase,
  UpdateSubmissionUseCase,
} from '@module/assignment';
import {
  FILE_STORAGE_REPOSITORY,
  IFileStorageRepository,
  SaveFileUseCase,
} from '@module/file-storage';

export const answerUseCaseProviders = [
  {
    provide: ANSWER_COMMENT_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new AnswerCommentUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: GRADING_ANSWER_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new GradingAnswerUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_SUBMISSION_USE_CASE,
    useFactory: (submissionRepository: ISubmissionRepository) => {
      return new UpdateSubmissionUseCase(submissionRepository);
    },
    inject: [SUBMISSION_REPOSITORY],
  },
  {
    provide: UPDATE_ESSAY_ANSWER_USE_CASE,
    useFactory: (
      submissionRepository: ISubmissionRepository,
      fileStorageRepository: IFileStorageRepository,
    ) => {
      return new UpdateEssayAnswerUseCase(
        submissionRepository,
        new SaveFileUseCase(fileStorageRepository),
      );
    },
    inject: [SUBMISSION_REPOSITORY, FILE_STORAGE_REPOSITORY],
  },
];
