import { AudioFile, Form, Question, QuestionProps, Section, SectionSummary } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';
import { FormPreviewAudioStats, FormPreviewTemporaryAudioStats } from './form-preview.states';

export const questionsSelector = (state: RootReducerState): Question<QuestionProps>[] =>
  state.formPreview.questions;

export const sectionsSelector = (state: RootReducerState): Section[] => state.formPreview.sections;

export const loadingQuestionsSelector = (state: RootReducerState): boolean =>
  state.formPreview.isLoadingQuestions;

export const formSelector = (state: RootReducerState): Form => state.formPreview.form;

export const loadingFormSelector = (state: RootReducerState): boolean =>
  state.formPreview.isLoadingForm;

export const selectedSectionSelector = (state: RootReducerState): Section =>
  state.formPreview.selectedSection;

export const selectedSectionIndexSelector = (state: RootReducerState): number =>
  state.formPreview.selectedSectionIndex;

export const sectionSummariesSelector = (state: RootReducerState): SectionSummary[] =>
  state.formPreview.sectionSummaries;

export const activeKeySelector = (state: RootReducerState): number[] => state.formPreview.activeKey;

export const audioStatsSelector = (state: RootReducerState): FormPreviewAudioStats[] =>
  state.formPreview.audioStats;

export const temporaryAudioStatsSelector = (
  state: RootReducerState,
): FormPreviewTemporaryAudioStats[] => state.formPreview.temporaryAudioStats;

export const isAutoPlayAudioSectionSelector = (state: RootReducerState): boolean =>
  state.formPreview.isAutoPlayAudioSection;

export const currentPlayingAudioSelector = (state: RootReducerState): AudioFile =>
  state.formPreview.currentPlayingAudio;
