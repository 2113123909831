import { UseCaseError } from '@digibricks/domains';
import { createReducer } from '@lib/plugin-redux-core';
import {
  handleGetGeneralInformationReportUseCase,
  handleGetQuestionReportUseCase,
  handleUpdateQuestionReportBookmarkUseCase,
} from './report.handler';
import {
  GET_GENERAL_INFORMATION_REPORT_USE_CASE,
  GET_QUESTION_REPORTS_USE_CASE,
  GeneralInformationReport,
  QuestionReport,
  QuestionReportProps,
  UPDATE_QUESTION_REPORT_BOOKMARK_USE_CASE,
} from '@module/report';

export type ReportInitialState = {
  error?: UseCaseError<any>;
};
export type GetGeneralInformationReportUseCaseState = {
  generalInformationReportResponse: GeneralInformationReport;
  isLoadingGetGeneralInformationReport: boolean;
  getGeneralInformationReportStatus: number;
};

export type GetQuestionReportUseCaseState = {
  questionReportsResponse: QuestionReport<QuestionReportProps>[];
  bookmarkedQuestionReports: QuestionReport<QuestionReportProps>[];
  isLoadingGetQuestionReports: boolean;
  getQuestionReportsStatus: number;
};

export type UpdateQuestionReportBookmarkUseCaseState = {
  isLoadingUpdateQuestionReportBookmark: boolean;
  updateQuestionReportBookmarkStatus: number;
};

export type ReportState = ReportInitialState &
  GetGeneralInformationReportUseCaseState &
  GetQuestionReportUseCaseState &
  UpdateQuestionReportBookmarkUseCaseState;

const initialState: ReportInitialState = {
  error: null,
};

export const reportReducer = createReducer(initialState, [
  {
    name: GET_GENERAL_INFORMATION_REPORT_USE_CASE,
    ...handleGetGeneralInformationReportUseCase,
  },
  {
    name: GET_QUESTION_REPORTS_USE_CASE,
    ...handleGetQuestionReportUseCase,
  },
  {
    name: UPDATE_QUESTION_REPORT_BOOKMARK_USE_CASE,
    ...handleUpdateQuestionReportBookmarkUseCase,
  },
]);
