import { Question, QuestionProps, Section, SectionSummary } from '@module/form';
import { RootReducerState } from '../../../redux/root.reducer';

export * from './section-item/section-item.selectors';
export * from './question-item/question-item.selectors';

export const isLoadingGetQuestionsSelector = (state: RootReducerState): boolean =>
  state.formEdit.isLoadingGetQuestions;

export const editingQuestionIdSelector = (state: RootReducerState): string =>
  state.formEdit.editingQuestionId;

export const hasUnsavedQuestionOrSectionChangesSelector = (state: RootReducerState): boolean =>
  state.formEdit.hasUnsavedQuestionOrSectionChanges;

export const findSectionsSelector = (state: RootReducerState): Section[] => state.formEdit.sections;

export const findSectionSummariesSelector = (state: RootReducerState): SectionSummary[] =>
  state.formEdit.sectionSummaries;

export const questionListSelector = (state: RootReducerState): Question<QuestionProps>[] =>
  state.formEdit.questions;

export const questionsSelector = (state: RootReducerState): Question<QuestionProps>[] =>
  state.formEdit.questions;

export const selectedFormItemIdSelector = (state: RootReducerState): string =>
  state.formEdit.selectedFormItemId;

export const editingFormItemIdSelector = (state: RootReducerState): string =>
  state.formEdit.editingFormItemId;
