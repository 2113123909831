const toggleSidebarName = 'toggle-sidebar';
const profileKey = 'profile';

const setLocalStorageToggleSidebar = (value: boolean) => {
  localStorage.setItem(toggleSidebarName, JSON.stringify(value));
};

const getLocalStorageToggleSidebar = () => {
  const toggle = localStorage.getItem(toggleSidebarName);
  if (toggle) {
    return JSON.parse(toggle);
  }
  return false;
};

const getProfileFromLocalStorage = () => {
  const profile = localStorage.getItem(profileKey);
  if (profile) {
    return JSON.parse(profile);
  }
  return false;
};

export { setLocalStorageToggleSidebar, getLocalStorageToggleSidebar, getProfileFromLocalStorage };
