import {
  FIND_GROUPED_QUESTIONS_BY_SECTION_USE_CASE,
  FindGroupedQuestionBySectionUseCaseOutput,
  Questions,
  Section,
} from '@module/form';
import { questionItemHandlers } from './question-item';
import { sectionItemHandlers } from './section-item';
import { FormEditPageState } from '../form-edit.states';
import { Action, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  FindGroupedQuestionsBySectionUseCaseState,
  OpenEditItemInFormEditUseCaseState,
  SelectItemInFormEditUseCaseState,
} from './tab-questions.state';
import { OPEN_EDIT_ITEM_IN_FORM_EDIT, SELECT_ITEM_IN_FORM_EDIT } from 'redux/root.action';

export const handleFindGroupedQuestionsBySection: UseCaseStateGenerator<FindGroupedQuestionsBySectionUseCaseState> =
  {
    name: FIND_GROUPED_QUESTIONS_BY_SECTION_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        isLoadingFindGroupedQuestionsBySectionState: true,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const output = payload as FindGroupedQuestionBySectionUseCaseOutput;
      const { sections } = state;
      const { sectionSummaries, questions } = output;

      const sortedQuestions = Questions.sortQuestionsWithIndexAndSection(questions);

      const hasEssayOrFillInBlankQuestion =
        Questions.hasEssayOrFillInBlankQuestion(sortedQuestions);
      let selectedSection: Section;
      let selectedFormItemId: string;
      if (sections) {
        selectedSection = sections[0];
        selectedFormItemId = sections[0].id.toString();
      }

      return {
        ...state,
        questions: sortedQuestions,
        selectedSection,
        selectedFormItemId,
        sectionSummaries,
        isQuestionEditing: false,
        isFormHasQuestions: questions.length > 0,
        hasEssayOrFillInBlankQuestion,
        isLoadingGetQuestions: false,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        isLoadingGetQuestions: false,
      };
    },
  };

export const handleSelectItemInFormEditUseCase: UseCaseStateGenerator<SelectItemInFormEditUseCaseState> =
  {
    name: SELECT_ITEM_IN_FORM_EDIT,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const selectedFormItemId = action.payload;
      return { ...state, selectedFormItemId };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return { ...state, selectedFormItemId: '' };
    },
  };

export const handleOpenEditItemInFormEditUseCase: UseCaseStateGenerator<OpenEditItemInFormEditUseCaseState> =
  {
    name: OPEN_EDIT_ITEM_IN_FORM_EDIT,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const editingFormItemId = action.payload;
      return { ...state, editingFormItemId };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return { ...state, editingFormItemId: '' };
    },
  };

export const tabQuestionsHandlers = [
  handleFindGroupedQuestionsBySection,
  handleSelectItemInFormEditUseCase,
  handleOpenEditItemInFormEditUseCase,
  ...questionItemHandlers,
  ...sectionItemHandlers,
];
