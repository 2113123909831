import { RequestStatus } from '@lib/plugin-redux-core';
import { RootReducerState } from '../../../../../redux/root.reducer';

export const createAnswerStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createAnswerStatus;
export const updateAnswerStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateAnswerStatus;
export const deleteAnswerStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.deleteAnswerStatus;

export const updateCorrectAnswerStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateCorrectAnswerStatus;

export const updateFillInBlankCorrectAnswerStatusSelector = (
  state: RootReducerState,
): RequestStatus => state.formEdit.updateFillInBlankCorrectAnswerStatus;

export const createAnswerChoiceQuestionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createAnswerStatus;

export const createSubQuestionAndCorrectAnswerStatusSelector = (
  state: RootReducerState,
): RequestStatus => state.formEdit.createSubQuestionAndCorrectAnswerStatus;
