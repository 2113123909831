import { GeneralInformationReport, QuestionReport, QuestionReportProps } from '@module/report';
import { ReportState } from './report.reducer';
import { Action } from '@lib/plugin-redux-core';
import { REQUEST_STATUS } from '@utils';

export const handleGetGeneralInformationReportUseCase = {
  executing: (state: ReportState): ReportState => {
    return {
      ...state,
      isLoadingGetGeneralInformationReport: true,
      getGeneralInformationReportStatus: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: ReportState, action: Action): ReportState => {
    const { payload } = action;

    return {
      ...state,
      generalInformationReportResponse: payload as GeneralInformationReport,
      getGeneralInformationReportStatus: REQUEST_STATUS.SUCCESS,
      isLoadingGetGeneralInformationReport: false,
    };
  },
  error: (state: ReportState, action: Action): ReportState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingGetGeneralInformationReport: false,
      getGeneralInformationReportStatus: REQUEST_STATUS.FAIL,
    };
  },
};

export const handleGetQuestionReportUseCase = {
  executing: (state: ReportState): ReportState => {
    return {
      ...state,
      isLoadingGetQuestionReports: true,
      getQuestionReportsStatus: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: ReportState, action: Action): ReportState => {
    const { payload } = action;
    const bookmarkedQuestionReports = payload.filter(
      (report: QuestionReport<QuestionReportProps>) => report.isBookmarked,
    );

    return {
      ...state,
      questionReportsResponse: payload as QuestionReport<QuestionReportProps>[],
      bookmarkedQuestionReports: bookmarkedQuestionReports,
      getQuestionReportsStatus: REQUEST_STATUS.SUCCESS,
      isLoadingGetQuestionReports: false,
    };
  },
  error: (state: ReportState, action: Action): ReportState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingGetQuestionReports: false,
      getQuestionReportsStatus: REQUEST_STATUS.FAIL,
    };
  },
  reset: (state: ReportState): ReportState => {
    return {
      ...state,
      questionReportsResponse: [],
      isLoadingGetQuestionReports: false,
      getQuestionReportsStatus: REQUEST_STATUS.DEFAULT,
    };
  },
};

export const handleUpdateQuestionReportBookmarkUseCase = {
  executing: (state: ReportState, action: Action): ReportState => {
    const { payload } = action;
    const bookmarkedQuestionReport = state.questionReportsResponse.find(
      (questionReport) => questionReport.id === payload.questionReportId,
    );

    return {
      ...state,
      isLoadingUpdateQuestionReportBookmark: true,
      updateQuestionReportBookmarkStatus: REQUEST_STATUS.DEFAULT,
      bookmarkedQuestionReports: [...state.bookmarkedQuestionReports, bookmarkedQuestionReport],
    };
  },
  success: (state: ReportState): ReportState => {
    return {
      ...state,
      updateQuestionReportBookmarkStatus: REQUEST_STATUS.SUCCESS,
      isLoadingUpdateQuestionReportBookmark: false,
    };
  },
  error: (state: ReportState, action: Action): ReportState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingUpdateQuestionReportBookmark: false,
      updateQuestionReportBookmarkStatus: REQUEST_STATUS.FAIL,
    };
  },
};
