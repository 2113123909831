import { showToastReducer } from '@lib/common';
import { createReducer } from '@lib/plugin-redux-core';

export type ToastInitialState = {};

export type ToastState = {};

const initialState: ToastInitialState = {};

export const toastReducer = createReducer(initialState, [showToastReducer()]);
