import { Module } from '@nestjs/common';
import { useCaseProviders } from './usecase-providers';
import { authenticationRepositoryProvider, userWebRepositoryProvider } from '@module/user';
import {
  formWebRepositoryProvider,
  folderWebRepositoryProvider,
  questionWebRepositoryProvider,
  gradeRepositoryProvider,
  subjectRepositoryProvider,
  spaceWebRepositoryProvider,
  questionReleasesWebRepositoryProvider,
  formReleaseWebRepositoryProvider,
  FormReleaseMapper,
} from '@module/form';
import { apiProviders } from './api-providers';
import {
  AssignmentMapper,
  SecurityOptionMapper,
  assignmentConfigurationRepositoryProvider,
  assignmentRepositoryProvider,
  examinationAssignmentRepositoryProvider,
  examinationSubmissionRepositoryProvider,
  homeworkAssignmentRepositoryProvider,
  practiceAssignmentRepositoryProvider,
  securityOptionRepositoryProvider,
  submissionRepositoryProvider,
} from '@module/assignment';
import { cachedProviders } from './cached-providers';
import {
  externalFileStorageRepositoryProvider,
  FileStorageMapper,
  fileStorageRepositoryProvider,
} from '@module/file-storage';
import { reportRepositoryProvider } from '@module/report';

@Module({
  providers: [
    ...cachedProviders,
    ...apiProviders,
    authenticationRepositoryProvider,
    formWebRepositoryProvider,
    folderWebRepositoryProvider,
    questionWebRepositoryProvider,
    userWebRepositoryProvider,
    spaceWebRepositoryProvider,
    gradeRepositoryProvider,
    subjectRepositoryProvider,
    assignmentRepositoryProvider,
    practiceAssignmentRepositoryProvider,
    homeworkAssignmentRepositoryProvider,
    examinationAssignmentRepositoryProvider,
    assignmentConfigurationRepositoryProvider,
    submissionRepositoryProvider,
    questionReleasesWebRepositoryProvider,
    formReleaseWebRepositoryProvider,
    questionReleasesWebRepositoryProvider,
    fileStorageRepositoryProvider,
    externalFileStorageRepositoryProvider,
    reportRepositoryProvider,
    securityOptionRepositoryProvider,
    examinationSubmissionRepositoryProvider,
    FileStorageMapper,
    AssignmentMapper,
    FormReleaseMapper,
    SecurityOptionMapper,
    ...useCaseProviders,
  ],
  exports: [...useCaseProviders],
})
export class AppModule {}
