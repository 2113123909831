import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  CREATE_EXAMINATION_ASSIGNMENT_USE_CASE,
  CREATE_HOMEWORK_ASSIGNMENT_USE_CASE,
  CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
  ExaminationAssignment,
  HomeworkAssignment,
  PracticeAssignment,
} from '@module/assignment';
import { CreateAssignmentUseCaseState } from './form-header.states';
import { FormEditPageState } from '../form-edit.states';

export const handleCreatePracticeAssignmentUseCase: UseCaseStateGenerator<CreateAssignmentUseCaseState> =
  {
    name: CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAssignmentStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        createAssignmentStatus: RequestStatus.SUCCESS,
        createAssignmentResponse: payload as PracticeAssignment,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        createAssignmentStatus: RequestStatus.ERROR,
        error: error,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAssignmentStatus: RequestStatus.RESET,
      };
    },
  };

export const handleCreateHomeworkAssignmentUseCase: UseCaseStateGenerator<CreateAssignmentUseCaseState> =
  {
    name: CREATE_HOMEWORK_ASSIGNMENT_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAssignmentStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        createAssignmentResponse: payload as HomeworkAssignment,
        createAssignmentStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        createAssignmentStatus: RequestStatus.ERROR,
        error: error,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAssignmentStatus: RequestStatus.RESET,
      };
    },
  };

export const handleCreateExaminationAssignmentUseCase: UseCaseStateGenerator<CreateAssignmentUseCaseState> =
  {
    name: CREATE_EXAMINATION_ASSIGNMENT_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAssignmentStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        createAssignmentResponse: payload as ExaminationAssignment,
        createAssignmentStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        createAssignmentStatus: RequestStatus.ERROR,
        error: error,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        createAssignmentStatus: RequestStatus.RESET,
      };
    },
  };

export const formHeaderHandlers = [
  handleCreatePracticeAssignmentUseCase,
  handleCreateHomeworkAssignmentUseCase,
  handleCreateExaminationAssignmentUseCase,
];

// export const handleGetAssignmentsUseCase: UseCaseStateGenerator<GetAssignmentsUseCaseState> = {
//   name: CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
// }

// export const handleGetAssignmentsUseCase: UseCaseStateGenerator<GetAssignmentsUseCaseState> = {
//   name: CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
// }
