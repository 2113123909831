import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  GetGradesAndSubjectsUseCaseState,
  UpdateFormInformationUseCaseState,
} from './tab-info.states';
import { FormEditPageState } from '../form-edit.states';
import {
  GET_GRADES_AND_SUBJECTS_USE_CASE,
  Grade,
  Subject,
  UPDATE_FORM_INFORMATION_USE_CASE,
} from '@module/form';

export const handleUpdateFormInformationUseCase: UseCaseStateGenerator<UpdateFormInformationUseCaseState> =
  {
    name: UPDATE_FORM_INFORMATION_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return { ...state, updateFormInformationStatus: RequestStatus.EXECUTE };
    },
    success: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateFormInformationStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        updateFormInformationStatus: RequestStatus.ERROR,
      };
    },
  };

export const handleGetGradesAndSubjectsUseCase: UseCaseStateGenerator<GetGradesAndSubjectsUseCaseState> =
  {
    name: GET_GRADES_AND_SUBJECTS_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        getGradesAndSubjectsStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      return {
        ...state,
        gradeAndSubjectResponses: payload as [Grade[], Subject[]],
        getGradesAndSubjectsStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error: error,
        getGradesAndSubjectsStatus: RequestStatus.ERROR,
      };
    },
  };

export const tabInfoHandlers = [
  handleUpdateFormInformationUseCase,
  handleGetGradesAndSubjectsUseCase,
];
