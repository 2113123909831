import {
  SAVE_FILE_USE_CASE,
  CREATE_QUESTION_ANSWER_FILE_STORAGE_USE_CASE,
  CREATE_QUESTION_FILE_STORAGE_USE_CASE,
  SaveFileUseCase,
  CreateQuestionAnswerFileUseCase,
  CreateQuestionFileUseCase,
  FILE_STORAGE_REPOSITORY,
  IFileStorageRepository,
  DELETE_FILE_USE_CASE,
  DeleteFileUseCase,
  IExternalFileStorageRepository,
  EXTERNAL_FILE_STORAGE_REPOSITORY,
} from '@module/file-storage';

export const fileStorageUseCaseProviders = [
  {
    provide: CREATE_QUESTION_FILE_STORAGE_USE_CASE,
    useFactory: (
      fileStorageRepository: IFileStorageRepository,
      externalFileStorageRepository: IExternalFileStorageRepository,
    ) => {
      return new CreateQuestionFileUseCase(fileStorageRepository, externalFileStorageRepository);
    },
    inject: [FILE_STORAGE_REPOSITORY, EXTERNAL_FILE_STORAGE_REPOSITORY],
  },
  {
    provide: CREATE_QUESTION_ANSWER_FILE_STORAGE_USE_CASE,
    useFactory: (
      fileStorageRepository: IFileStorageRepository,
      externalFileStorageRepository: IExternalFileStorageRepository,
    ) => {
      return new CreateQuestionAnswerFileUseCase(
        fileStorageRepository,
        externalFileStorageRepository,
      );
    },
    inject: [FILE_STORAGE_REPOSITORY, EXTERNAL_FILE_STORAGE_REPOSITORY],
  },
  {
    provide: SAVE_FILE_USE_CASE,
    useFactory: (fileStorageRepository: IFileStorageRepository) => {
      return new SaveFileUseCase(fileStorageRepository);
    },
    inject: [FILE_STORAGE_REPOSITORY],
  },
  {
    provide: DELETE_FILE_USE_CASE,
    useFactory: (fileStorageRepository: IFileStorageRepository) => {
      return new DeleteFileUseCase(fileStorageRepository);
    },
    inject: [FILE_STORAGE_REPOSITORY],
  },
];
