import {
  AssignmentConfigurations,
  AssignmentSummary,
  ExaminationAssignment,
  HomeworkAssignment,
  PracticeAssignment,
  SecurityOption,
} from '@module/assignment';
import { RootReducerState } from '../../root.reducer';
import { RequestStatus } from '@lib/plugin-redux-core';

export const assignmentResponseSelector = (state: RootReducerState): AssignmentSummary[] =>
  state.formEdit.assignmentResponses || [];

export const loadingGetAssignmentsSelector = (state: RootReducerState): boolean =>
  state.formEdit.isLoadingGetAssignments;

export const assignmentConfigsResponseSelector = (
  state: RootReducerState,
): AssignmentConfigurations => state.formEdit.assignmentConfigsResponse;

export const createAssignmentStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createAssignmentStatus;

export const deleteAssignmentStatusSelector = (state: RootReducerState): number =>
  state.formEdit.deleteAssignmentStatus;

export const createAssignmentResponseSelector = (
  state: RootReducerState,
): PracticeAssignment | HomeworkAssignment | ExaminationAssignment =>
  state.formEdit.createAssignmentResponse;

export const effectSelector = (state: RootReducerState): number => state.formEdit.effect;

export const getSecurityOptionsStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.getSecurityOptionsStatus;

export const securityOptionsSelector = (state: RootReducerState): SecurityOption[] =>
  state.formEdit.securityOptions;
