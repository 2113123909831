import { RequestStatus } from '@lib/plugin-redux-core';
import { RootReducerState } from '../../../redux/root.reducer';
import { Grade, Subject } from '@module/form';

export const updateFormInformationStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.formEdit.updateFormInformationStatus;
};

export const gradesAndSubjectsSelector = (state: RootReducerState): [Grade[], Subject[]] =>
  state.formEdit.gradeAndSubjectResponses;
