export var QuestionFieldType;

(function (QuestionFieldType) {
  QuestionFieldType["id"] = "id";
  QuestionFieldType["description"] = "description";
  QuestionFieldType["type"] = "type";
  QuestionFieldType["explanation"] = "explanation";
  QuestionFieldType["answers"] = "answers";
  QuestionFieldType["correctAnswers"] = "correctAnswers";
  QuestionFieldType["answerDescription"] = "answerDescription";
  QuestionFieldType["answerScore"] = "answerScore";
  QuestionFieldType["subQuestion"] = "subQuestion";
  QuestionFieldType["correctAnswerSelection"] = "correctAnswerSelection";
  QuestionFieldType["correctAnswerScore"] = "correctAnswerScore";
})(QuestionFieldType || (QuestionFieldType = {}));