import { createReducer } from '@lib/plugin-redux-core';
import {
  Assignment,
  AssignmentProps,
  AttendeeDetail,
  EssayAnswer,
  FillInBlankGroupAnswer,
  GET_ANSWERS_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_ATTENDEE_BY_ID_USE_CASE,
  GET_SUBMISSION_BY_ID_USE_CASE,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import {
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
  FIND_QUESTION_RELEASES_USE_CASE,
  FormRelease,
  QuestionRelease,
  QuestionReleaseProps,
} from '@module/form';
import { UseCaseError } from '@digibricks/domains';
import {
  handleGetSubmissionById,
  handleGetAssignmentByIdUseCase,
  handleGetFormReleaseByIdUseCase,
  handleGetQuestionReleasesUseCase,
  handleGetAttendeeByIdUseCase,
  handleGetAnswersUseCase,
} from './submission-preview.handler';

export enum SubmissionPreviewPageStatus {
  EXECUTING,
  SUCCESS,
  ERROR,
  RESET,
}

export type SubmissionPreviewPageInitialState = {
  error?: UseCaseError<any>;
};

export type GetSubmissionByIdUseCaseState = {
  submission: SubmissionSummary;
  getSubmissionByIdStatus: SubmissionPreviewPageStatus;
};

export type GetAssignmentByIdUseCaseState = {
  assignment: Assignment<AssignmentProps>;
  getAssignmentByIdStatus: SubmissionPreviewPageStatus;
};

export type GetFormReleaseByIdUseCaseState = {
  formRelease: FormRelease;
  getFormReleaseByIdStatus: SubmissionPreviewPageStatus;
};

export type GetAttendeeByIdUseCaseState = {
  attendeeDetail: AttendeeDetail;
  getAttendeeByIdStatus: SubmissionPreviewPageStatus;
};

export type GetQuestionReleasesUseCaseState = {
  questionReleases: QuestionRelease<QuestionReleaseProps>[];
  getQuestionReleasesStatus: SubmissionPreviewPageStatus;
};

export type GetSubmissionAnswersUseCaseState = {
  answers: (EssayAnswer | SingleChoiceAnswer | FillInBlankGroupAnswer | MultipleChoiceAnswer)[];
  getAnswersStatus: SubmissionPreviewPageStatus;
};

export type SubmissionPreviewPageState = SubmissionPreviewPageInitialState &
  GetSubmissionByIdUseCaseState &
  GetAssignmentByIdUseCaseState &
  GetFormReleaseByIdUseCaseState &
  GetQuestionReleasesUseCaseState &
  GetAttendeeByIdUseCaseState &
  GetSubmissionAnswersUseCaseState;

export const initialState: SubmissionPreviewPageInitialState = {
  error: null,
};

export const submissionPreviewReducer = createReducer(initialState, [
  {
    name: GET_SUBMISSION_BY_ID_USE_CASE,
    ...handleGetSubmissionById,
  },
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdUseCase,
  },
  {
    name: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    ...handleGetFormReleaseByIdUseCase,
  },
  {
    name: GET_ATTENDEE_BY_ID_USE_CASE,
    ...handleGetAttendeeByIdUseCase,
  },
  {
    name: FIND_QUESTION_RELEASES_USE_CASE,
    ...handleGetQuestionReleasesUseCase,
  },
  {
    name: GET_ANSWERS_USE_CASE,
    ...handleGetAnswersUseCase,
  },
]);
