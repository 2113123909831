import { Action, createReducer } from '@lib/plugin-redux-core';
import { SIDEBAR_USE_CASE } from '../root.action';

const initialState = {
  sidebarState: false,
};

export const sidebarReducer = createReducer(initialState, [
  {
    name: SIDEBAR_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        sidebarState: payload.isSidebarCollapsed as boolean,
      };
    },
    reset: (state: {}) => {
      return { ...state, sidebarState: false };
    },
  },
]);
