import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  ADD_QUESTION_AUDIO_FILE_USE_CASE,
  ADD_QUESTION_FILE_USE_CASE,
  AddQuestionFileUseCaseInput,
  CANCEL_QUESTION_USE_CASE,
  CancelQuestionUseCaseOutput,
  CREATE_QUESTION_USE_CASE,
  CreateQuestionUseCaseOutput,
  DELETE_QUESTION_AUDIO_FILE_USE_CASE,
  DELETE_QUESTION_USE_CASE,
  DeleteQuestionUseCaseInput,
  DeleteQuestionUseCaseOutput,
  PreviousQuestionId,
  Question,
  QuestionProps,
  Questions,
  QuestionStatus,
  QuestionType,
  SAVE_QUESTION_USE_CASE,
  SectionSummary,
  UPDATE_QUESTION_AUDIO_FILE_USE_CASE,
  UPDATE_QUESTION_TYPE_USE_CASE,
  UPDATE_QUESTION_USE_CASE,
  UpdateQuestionInput,
  UpdateQuestionUseCaseInput,
} from '@module/form';
import { FormEditPageState } from '../../form-edit.states';
import { OPEN_EDIT_QUESTION_USE_CASE, SET_QUESTION_IN_VIEW } from '../../../../redux/root.action';
import {
  AddQuestionFileUseCaseState,
  CreateQuestionUseCaseState,
  CreateQuestionAnswerFileUseCaseState,
  CreateQuestionFileUseCaseState,
  DeleteQuestionUseCaseState,
  QuestionInViewUseCaseState,
  SaveQuestionUseCaseState,
  UpdateQuestionUseCaseState,
  OpenEditQuestionUseCaseState,
  UpdateQuestionAudioFileUseCaseState,
  AddQuestionAudioFileUseCaseState,
  DeleteQuestionAudioFileUseCaseState,
} from './question-item.states';
import { answerItemHandlers, handleCreateAnswerStateUseCase } from './answer-item';
import {
  CREATE_QUESTION_ANSWER_FILE_STORAGE_USE_CASE,
  CREATE_QUESTION_FILE_STORAGE_USE_CASE,
  CreatedFileStorage,
} from '@module/file-storage';

export const handleOpenEditQuestion: UseCaseStateGenerator<OpenEditQuestionUseCaseState> = {
  name: OPEN_EDIT_QUESTION_USE_CASE,
  executing: (state: FormEditPageState, action: Action) => {
    const questionId = action.payload;
    const { questions } = state;
    const foundQuestion = questions.find((q) => q.id.toString() === questionId);
    const hasUnsavedQuestionOrSectionChanges = state.saveQuestionStatus !== RequestStatus.SUCCESS;
    const revertedQuestion = foundQuestion.clone();
    return {
      ...state,
      editingQuestionId: questionId,
      hasUnsavedQuestionOrSectionChanges,
      selectedFormItemId: questionId,
      editingFormItemId: questionId,
      revertedQuestion,
    };
  },
  reset: (state: FormEditPageState) => {
    return {
      ...state,
      editingQuestionId: null,
      isEditingQuestion: false,
      revertedQuestion: null,
      editingFormItemId: '',
    };
  },
};

export const handleSaveQuestionUseCase: UseCaseStateGenerator<SaveQuestionUseCaseState> = {
  name: SAVE_QUESTION_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      saveQuestionStatus: RequestStatus.EXECUTE,
      isQuestionEditing: false,
      isFormHasQuestions: true,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;

    const updatedQuestion = payload as Question<QuestionProps>;
    return {
      ...state,
      hasEssayOrFillInBlankQuestion: Questions.hasEssayOrFillInBlankQuestion(state.questions),
      saveQuestionStatus: RequestStatus.SUCCESS,
      editingQuestionId: null,
      unsavedQuestion: null,
      hasUnsavedQuestionOrSectionChanges: false,
      isQuestionEditing: false,
      revertedQuestion: null,
      selectedFormItemId: updatedQuestion.id.toString(),
      editingFormItemId: '',
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      saveQuestionStatus: RequestStatus.ERROR,
      hasUnsavedQuestionOrSectionChanges: false,
      isQuestionEditing: false,
      editingFormItemId: '',
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      editingQuestionId: null,
      saveQuestionStatus: RequestStatus.RESET,
      updateQuestionOrAnswerStatus: RequestStatus.RESET,
      hasUnsavedQuestionOrSectionChanges: false,
      isQuestionEditing: false,
      editingFormItemId: '',
    };
  },
};

export const handleCreateLocalQuestionUseCase: UseCaseStateGenerator<CreateQuestionUseCaseState> = {
  name: CREATE_QUESTION_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      createdQuestionStatus: RequestStatus.EXECUTE,
      isQuestionEditing: true,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const sectionSummaries = [...state.sectionSummaries];
    const { payload } = action;
    const output = payload as CreateQuestionUseCaseOutput;

    const { newQuestion, nextQuestion } = output;
    const questions = [...state.questions];
    questions.push(newQuestion);

    if (nextQuestion) {
      const nextQuestionIndex = questions.findIndex(
        (q) => q.id.toString() === nextQuestion.id.toString(),
      );
      questions[nextQuestionIndex] = nextQuestion.clone();
    }
    const sortedQuestions = Questions.sortQuestionsWithIndexAndSection(questions);
    const updatedSectionIndex = sectionSummaries.findIndex(
      (section) => section.id.toString() === newQuestion.sectionId,
    );
    sectionSummaries[updatedSectionIndex] = SectionSummary.cloneFromSectionAndQuestions(
      sectionSummaries[updatedSectionIndex],
      sortedQuestions,
    );
    return {
      ...state,
      sectionSummaries,
      questions: sortedQuestions,
      isFormHasQuestions: true,
      createdQuestionStatus: RequestStatus.SUCCESS,
      hasUnsavedQuestionOrSectionChanges: true,
      newQuestion: newQuestion.clone(),
      editingQuestionId: newQuestion.id.toString(),
      selectedFormItemId: newQuestion.id.toString(),
      editingFormItemId: newQuestion.id.toString(),
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      createdQuestionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      createdQuestionStatus: RequestStatus.RESET,
    };
  },
};

export const handleCreateQuestionFileUseCase: UseCaseStateGenerator<CreateQuestionFileUseCaseState> =
  {
    name: CREATE_QUESTION_FILE_STORAGE_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      return {
        ...state,
        uploadingFile: action.payload.file,
        createQuestionFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        createQuestionFileStatus: RequestStatus.SUCCESS,
        selectedFile: payload as CreatedFileStorage,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error: error,
        createQuestionFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        selectedFile: null,
        createQuestionFileStatus: RequestStatus.RESET,
      };
    },
  };
export const handleCreateQuestionAnswerFileUseCase: UseCaseStateGenerator<CreateQuestionAnswerFileUseCaseState> =
  {
    name: CREATE_QUESTION_ANSWER_FILE_STORAGE_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      return {
        ...state,
        uploadingFile: action.payload.file,
        createQuestionAnswerFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        createQuestionAnswerFileStatus: RequestStatus.SUCCESS,
        selectedFile: payload as CreatedFileStorage,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error: error,
        createQuestionAnswerFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        selectedFile: null,
        createQuestionAnswerFileStatus: RequestStatus.RESET,
      };
    },
  };

export const handleAddQuestionFileUseCase: UseCaseStateGenerator<AddQuestionFileUseCaseState> = {
  name: ADD_QUESTION_FILE_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    return {
      ...state,
      addQuestionFileInput: action.payload as AddQuestionFileUseCaseInput,
      addQuestionFileStatus: RequestStatus.EXECUTE,
      isQuestionEditing: false,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const updatedQuestion = payload as Question<QuestionProps>;
    const questions: Question<QuestionProps>[] = state.questions.filter(
      (q) => q.id.toString() !== updatedQuestion.id.toString(),
    );
    questions.push(updatedQuestion);
    const sortQuestions = Questions.sortQuestionsWithIndexAndSection(questions);

    return {
      ...state,
      questions: sortQuestions,
      unsavedQuestion: updatedQuestion,
      addQuestionFileStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      addQuestionFileStatus: RequestStatus.ERROR,
      isQuestionEditing: false,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      selectedFile: null,
      addQuestionFileStatus: RequestStatus.RESET,
      isQuestionEditing: false,
    };
  },
};

export const handleUpdateQuestionStateUseCase: UseCaseStateGenerator<UpdateQuestionUseCaseState> = {
  name: UPDATE_QUESTION_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    return {
      ...state,
      updateQuestionUseCaseInput: action.payload as UpdateQuestionUseCaseInput<UpdateQuestionInput>,
      updateQuestionStatus: RequestStatus.EXECUTE,
      updateQuestionOrAnswerStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const updatedQuestion = payload as Question<QuestionProps>;

    const { questions, updateQuestionUseCaseInput } = state;
    const newQuestions = [...questions];
    const questionUpdateIndex = newQuestions.findIndex(
      (q) => q.id.toString() === updatedQuestion.id.toString(),
    );
    newQuestions[questionUpdateIndex] = updatedQuestion.clone();
    const sortedQuestions = Questions.sortQuestionsWithIndexAndSection(newQuestions);

    if (
      updateQuestionUseCaseInput?.data?.score ||
      updatedQuestion.type === QuestionType.FillInBlank ||
      updatedQuestion.type === QuestionType.Matching
    ) {
      const sectionSummaries = [...state.sectionSummaries];
      const updatedSectionSummaryIndex = sectionSummaries.findIndex(
        (section) => section.id.toString() === updatedQuestion.sectionId,
      );
      sectionSummaries[updatedSectionSummaryIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionSummaryIndex],
        newQuestions,
      );

      return {
        ...state,
        unsavedQuestion: updatedQuestion,
        questions: sortedQuestions,
        updateQuestionStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
        sectionSummaries,
      };
    }
    return {
      ...state,
      unsavedQuestion: updatedQuestion,
      questions: sortedQuestions,
      updateQuestionStatus: RequestStatus.SUCCESS,
      updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    const { revertedQuestion } = state;
    const questions = state.questions.filter(
      (q) => q.id.toString() !== revertedQuestion.id.toString(),
    );
    questions.push(revertedQuestion);

    return {
      ...state,
      revertedQuestion: null,
      hasUnsavedQuestionOrSectionChanges: false,
      editingQuestionId: null,
      updateQuestionStatus: RequestStatus.RESET,
      updateQuestionOrAnswerStatus: RequestStatus.RESET,
    };
  },
};

export const handleUpdateQuestionTypeStateUseCase: UseCaseStateGenerator<UpdateQuestionUseCaseState> =
  {
    ...handleUpdateQuestionStateUseCase,
    name: UPDATE_QUESTION_TYPE_USE_CASE,
  };

export const handleCancelQuestionStateUseCase: UseCaseStateGenerator<UpdateQuestionUseCaseState> = {
  name: CANCEL_QUESTION_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      cancelQuestionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const questions = [...state.questions];
    const output = payload as CancelQuestionUseCaseOutput;
    const { canceledQuestion, nextQuestion } = output;

    const sectionSummaries = [...state.sectionSummaries];
    const updatedSectionIndex = sectionSummaries.findIndex(
      (section) => section.id.toString() === canceledQuestion.sectionId,
    );

    const previousItemOfCanceledQuestionId =
      canceledQuestion.previousQuestionId === PreviousQuestionId.none.id.toString()
        ? canceledQuestion.sectionId
        : canceledQuestion.previousQuestionId;

    if (canceledQuestion.status === QuestionStatus.Deactivate) {
      const newQuestions = questions.filter(
        (q) => q.id.toString() !== canceledQuestion.id.toString(),
      );

      if (nextQuestion) {
        const questionUpdateIndex = newQuestions.findIndex(
          (q) => q.id.toString() === canceledQuestion.id.toString(),
        );
        newQuestions[questionUpdateIndex] = canceledQuestion.clone();
      }

      sectionSummaries[updatedSectionIndex] = SectionSummary.cloneFromSectionAndQuestions(
        sectionSummaries[updatedSectionIndex],
        newQuestions,
      );

      return {
        ...state,
        sectionSummaries,
        questions: Questions.addIndex(newQuestions),
        cancelQuestionStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.RESET,
        hasUnsavedQuestionOrSectionChanges: false,
        editingQuestionId: null,
        revertedQuestion: null,
        isQuestionEditing: false,
        unsavedQuestion: null,
        selectedFormItemId: previousItemOfCanceledQuestionId,
        editingFormItemId: '',
      };
    }

    const questionUpdateIndex = questions.findIndex(
      (q) => q.id.toString() === canceledQuestion.id.toString(),
    );
    questions[questionUpdateIndex] = canceledQuestion;

    sectionSummaries[updatedSectionIndex] = SectionSummary.cloneFromSectionAndQuestions(
      sectionSummaries[updatedSectionIndex],
      questions,
    );
    return {
      ...state,
      questions: Questions.addIndex(questions),
      sectionSummaries,
      cancelQuestionStatus: RequestStatus.SUCCESS,
      updateQuestionOrAnswerStatus: RequestStatus.RESET,
      hasUnsavedQuestionOrSectionChanges: false,
      editingQuestionId: null,
      revertedQuestion: null,
      isQuestionEditing: false,
    };
  },
};

export const handleDeleteQuestionUseCase: UseCaseStateGenerator<DeleteQuestionUseCaseState> = {
  name: DELETE_QUESTION_USE_CASE,
  executing: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const { currentQuestion } = payload as DeleteQuestionUseCaseInput;

    const sectionSummaries = [...state.sectionSummaries];
    const sectionIdContainsDeletedQuestion = sectionSummaries
      .find((s) => s.id.toString() === currentQuestion.sectionId)
      ?.id.toString();

    return {
      ...state,
      deletedQuestionId: payload,
      sectionIdContainsDeletedQuestion,
      deleteQuestionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;
    const { nextQuestion, deletedQuestion } = payload as DeleteQuestionUseCaseOutput;
    const { questions } = state;
    const reSelectPreviousQuestionId =
      deletedQuestion.previousQuestionId === PreviousQuestionId.none.id.toString()
        ? deletedQuestion.sectionId
        : deletedQuestion.previousQuestionId;
    const updatedQuestions = questions.filter(
      (q) => q.id.toString() !== deletedQuestion.id.toString(),
    );

    if (nextQuestion) {
      const questionUpdateIndex = updatedQuestions.findIndex(
        (q) => q.id.toString() === nextQuestion.id.toString(),
      );
      updatedQuestions[questionUpdateIndex] = nextQuestion.clone();
    }

    const sectionSummaries = [...state.sectionSummaries];
    const updatedSectionIndex = sectionSummaries.findIndex(
      (section) => section.id.toString() === deletedQuestion.sectionId,
    );
    sectionSummaries[updatedSectionIndex] = SectionSummary.cloneFromSectionAndQuestions(
      sectionSummaries[updatedSectionIndex],
      updatedQuestions,
    );

    return {
      ...state,
      sectionSummaries,
      hasEssayOrFillInBlankQuestion: Questions.hasEssayOrFillInBlankQuestion(updatedQuestions),
      editingQuestionId: '',
      hasUnsavedQuestionOrSectionChanges: false,
      questions: Questions.sortQuestionsWithIndexAndSection(updatedQuestions),
      deleteQuestionStatus: RequestStatus.SUCCESS,
      selectedFormItemId: reSelectPreviousQuestionId,
      editingFormItemId: '',
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteQuestionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      deleteQuestionStatus: RequestStatus.RESET,
    };
  },
};

export const handleSetQuestionInViewUseCase: UseCaseStateGenerator<QuestionInViewUseCaseState> = {
  name: SET_QUESTION_IN_VIEW,
  executing: (state: FormEditPageState, action: Action) => {
    const questionId = action.payload;
    return {
      ...state,
      questionInView: questionId?.toString() || '',
    };
  },
  reset: (state: FormEditPageState) => {
    return {
      ...state,
      questionInView: null,
    };
  },
};
export const handleUpdateQuestionAudioFileUseCase: UseCaseStateGenerator<UpdateQuestionAudioFileUseCaseState> =
  {
    name: UPDATE_QUESTION_AUDIO_FILE_USE_CASE,
    executing: (state: FormEditPageState) => {
      return {
        ...state,
        updateQuestionAudioFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState) => {
      return {
        ...state,
        updateQuestionAudioFileStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error,
        updateQuestionAudioFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateQuestionAudioFileStatus: RequestStatus.RESET,
      };
    },
  };
export const handleDeleteQuestionAudioFileUseCase: UseCaseStateGenerator<DeleteQuestionAudioFileUseCaseState> =
  {
    name: DELETE_QUESTION_AUDIO_FILE_USE_CASE,
    executing: (state: FormEditPageState) => {
      return {
        ...state,
        deleteQuestionAudioFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState) => {
      return {
        ...state,
        deleteQuestionAudioFileStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error,
        deleteQuestionAudioFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        deleteQuestionAudioFileStatus: RequestStatus.RESET,
      };
    },
  };
export const handleAddQuestionAudioFileUseCase: UseCaseStateGenerator<AddQuestionAudioFileUseCaseState> =
  {
    name: ADD_QUESTION_AUDIO_FILE_USE_CASE,
    executing: (state: FormEditPageState) => {
      return {
        ...state,
        addQuestionAudioFileStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState) => {
      return {
        ...state,
        addQuestionAudioFileStatus: RequestStatus.SUCCESS,
        updateQuestionOrAnswerStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error,
        addQuestionAudioFileStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        addQuestionAudioFileStatus: RequestStatus.RESET,
      };
    },
  };
export const questionItemHandlers = [
  handleOpenEditQuestion,
  handleCreateLocalQuestionUseCase,
  handleDeleteQuestionUseCase,
  handleSaveQuestionUseCase,
  handleCreateQuestionFileUseCase,
  handleCreateQuestionAnswerFileUseCase,
  handleAddQuestionFileUseCase,
  handleUpdateQuestionTypeStateUseCase,
  handleUpdateQuestionStateUseCase,
  handleCancelQuestionStateUseCase,
  handleCreateAnswerStateUseCase,
  handleSetQuestionInViewUseCase,
  handleAddQuestionAudioFileUseCase,
  handleUpdateQuestionAudioFileUseCase,
  handleDeleteQuestionAudioFileUseCase,
  // answer handlers
  ...answerItemHandlers,
];
