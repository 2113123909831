import {
  AssignmentConfigurations,
  AssignmentSummary,
  Attendee,
  AttendeeSubmission,
  ExaminationAssignment,
  HomeworkAssignment,
  PracticeAssignment,
} from '@module/assignment';
import { AssignmentState } from './assignment.reducer';
import { Action, RequestStatus, reset } from '@lib/plugin-redux-core';
import { REQUEST_STATUS } from '@utils';

export const handleGetAssignmentUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      isLoading: true,
      getAssignmentSummaryStatus: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: AssignmentState, action: Action): AssignmentState => {
    const { payload } = action;

    return {
      ...state,
      assignmentResponses: payload as AssignmentSummary[],
      getAssignmentSummaryStatus: REQUEST_STATUS.SUCCESS,
      isLoading: false,
    };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoading: false,
      getAssignmentSummaryStatus: REQUEST_STATUS.FAIL,
    };
  },
};

export const handleDeleteAssignmentUseCase = {
  executing: (state: AssignmentState, action: Action): AssignmentState => {
    const { payload } = action;
    return {
      ...state,
      deletedAssignmentId: payload as string,
      deleteAssignmentStatus: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      deleteAssignmentStatus: REQUEST_STATUS.SUCCESS,
    };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deletedAssignmentId: null,
      deleteAssignmentStatus: REQUEST_STATUS.FAIL,
    };
  },
  reset: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      deletedAssignmentId: null,
      deleteAssignmentStatus: REQUEST_STATUS.DEFAULT,
    };
  },
};

export const handleGetAssignmentConfigurationsUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return { ...state, isLoadingConfigs: true };
  },
  success: (state: AssignmentState, action: Action): AssignmentState => {
    const { payload } = action;
    return {
      ...state,
      assignmentConfigsResponse: payload as AssignmentConfigurations,
      isLoadingConfigs: false,
    };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return { ...state, error: error, isLoadingConfigs: false };
  },
};

export const handleGetAssignmentByIdUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      getAssignmentByIdStatus: REQUEST_STATUS.DEFAULT,
      isLoadingGetById: true,
    };
  },
  success: (state: AssignmentState, action: Action): AssignmentState => {
    const { payload } = action;

    return {
      ...state,
      assignmentByIdResponse: payload as
        | PracticeAssignment
        | HomeworkAssignment
        | ExaminationAssignment,
      getAssignmentByIdStatus: REQUEST_STATUS.SUCCESS,
      isLoadingGetById: false,
    };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingGetById: false,
      getAssignmentByIdStatus: REQUEST_STATUS.FAIL,
    };
  },
  reset: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      assignmentByIdResponse: null,
      error: null,
      getAssignmentByIdStatus: REQUEST_STATUS.DEFAULT,
    };
  },
};

export const handleGetAttendeesUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      isLoadingGetAttendees: true,
      statusGetAttendee: REQUEST_STATUS.DEFAULT,
    };
  },
  success: (state: AssignmentState, action: Action): AssignmentState => {
    const { payload } = action;
    const attendeeResponses = payload.map((attendee: Attendee, index: number) => ({
      ...attendee.toJsonObject(),
      order: index + 1,
    }));
    return {
      ...state,
      attendeeResponses: attendeeResponses as Attendee[],
      isLoadingGetAttendees: false,
      statusGetAttendee: REQUEST_STATUS.SUCCESS,
    };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingGetAttendees: false,
      statusGetAttendee: REQUEST_STATUS.FAIL,
    };
  },
  reset: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      attendeeResponses: [],
      statusGetAttendee: REQUEST_STATUS.DEFAULT,
    };
  },
};

export const handleGetAttendeeSubmissionUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      statusGetAttendeeSubmission: REQUEST_STATUS.DEFAULT,
      isLoadingAttendeeSubmission: true,
    };
  },
  success: (state: AssignmentState, action: Action): AssignmentState => {
    const { payload } = action;
    return {
      ...state,
      attendeeSubmissionResponse: payload as AttendeeSubmission,
      statusGetAttendeeSubmission: REQUEST_STATUS.SUCCESS,
      isLoadingAttendeeSubmission: false,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      statusGetAttendeeSubmission: REQUEST_STATUS.FAIL,
      isLoadingAttendeeSubmission: false,
    };
  },
  reset: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      statusGetAttendeeSubmission: REQUEST_STATUS.DEFAULT,
      isLoadingAttendeeSubmission: false,
    };
  },
};

export const handlePublishScoreUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return { ...state, isLoadingPublishScore: true, publishScoreStatus: RequestStatus.EXECUTE };
  },
  success: (state: AssignmentState, action: Action): AssignmentState => {
    return {
      ...state,
      assignmentByIdResponse: action.payload as HomeworkAssignment,
      isLoadingPublishScore: false,
      publishScoreStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return {
      ...state,
      isLoadingPublishScore: false,
      publishScoreStatus: RequestStatus.ERROR,
      error,
    };
  },
  reset: (state: AssignmentState): AssignmentState => {
    return {
      ...state,
      publishScoreStatus: REQUEST_STATUS.DEFAULT,
      isLoadingPublishScore: false,
    };
  },
};

export const handleUpdateExaminationSubmissionUseCase = {
  executing: (state: AssignmentState): AssignmentState => {
    return { ...state, updateExaminationSubmissionStatus: RequestStatus.EXECUTE };
  },
  success: (state: AssignmentState): AssignmentState => {
    return { ...state, updateExaminationSubmissionStatus: RequestStatus.SUCCESS };
  },
  error: (state: AssignmentState, action: Action): AssignmentState => {
    const { error } = action;
    return { ...state, error, updateExaminationSubmissionStatus: RequestStatus.ERROR };
  },
  reset: (state: AssignmentState): AssignmentState => {
    return { ...state, updateExaminationSubmissionStatus: RequestStatus.RESET };
  },
};
