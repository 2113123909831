import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Action, createEpicForUseCase, success } from '@lib/plugin-redux-core';
import { bootstrapAuthDI, bootstrapDI, UseCaseConfig } from '../di/index';
import { notification } from 'antd';
import {
  DELETE_FOLDER_USE_CASE,
  DELETE_FORM_USE_CASE,
  DELETE_SECTION_USE_CASE,
  GET_FOLDER_BY_ID_USE_CASE,
  UPDATE_QUESTION_ANSWER_USE_CASE,
  UPDATE_FOLDER_USE_CASE,
  UPDATE_FORM_INFORMATION_USE_CASE,
  UPDATE_FORM_TITLE_USE_CASE,
} from '@module/form';
import { showToastHandler } from '@lib/common';
import { GET_USER_BY_ID_USE_CASE } from '@module/user';
import getConfig from 'next/config';

export const epic$ = new BehaviorSubject(combineEpics());
export const rootEpic = (action$: any, state$: any) =>
  epic$.pipe(mergeMap((epic) => epic(action$, state$, null)));

const cachedUseCases: UseCaseConfig[] = [];
const cachedAuthUseCases: UseCaseConfig[] = [];

export const runAsyncEpics = async () => {
  if (cachedUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapDI();
  cachedUseCases.push(...useCases);
  const epics = cachedUseCases.map((u: UseCaseConfig) => {
    return mappingShowToastHandlerByUseCase(u);
  });
  epics.forEach((e) => epic$.next(e));
};

export const runAuthAsyncEpics = async () => {
  if (cachedAuthUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapAuthDI();
  cachedAuthUseCases.push(...useCases);
  const epics = cachedAuthUseCases.map((u: UseCaseConfig) => {
    return mappingShowToastHandlerByUseCase(u);
  });
  epics.forEach((e) => epic$.next(e));
};

export const unAuthenticationHandler = () => {
  localStorage.clear();
  const { publicRuntimeConfig } = getConfig();
  window.location.href = `${publicRuntimeConfig.app.authentication.page.signIn.url}`;
};

export const mappingShowToastHandlerByUseCase = (u: UseCaseConfig) => {
  switch (u.name) {
    case GET_USER_BY_ID_USE_CASE:
    case GET_FOLDER_BY_ID_USE_CASE:
      return createEpicForUseCase(u.instance, u.name, showToastHandler, unAuthenticationHandler);
    case UPDATE_QUESTION_ANSWER_USE_CASE:
      return createEpicForUseCase(u.instance, u.name, showToastHandler, unAuthenticationHandler);
    case UPDATE_FORM_TITLE_USE_CASE:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showUpdateFormInformationToastHandler,
        unAuthenticationHandler,
      );
    case UPDATE_FORM_INFORMATION_USE_CASE:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showUpdateFormInformationToastHandler,
        unAuthenticationHandler,
      );
    case UPDATE_FOLDER_USE_CASE:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showUpdateFolderToastHandler,
        unAuthenticationHandler,
      );
    case DELETE_FORM_USE_CASE:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showDeleteFormToastHandler,
        unAuthenticationHandler,
      );
    case DELETE_FOLDER_USE_CASE:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showDeleteFolderToastHandler,
        unAuthenticationHandler,
      );
    case DELETE_SECTION_USE_CASE:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showDeleteSectionToastHandler,
        unAuthenticationHandler,
      );
    default:
      return createEpicForUseCase(
        u.instance,
        u.name,
        showCommonErrorToastHandler,
        unAuthenticationHandler,
      );
  }
};

const showDeleteFolderToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('deleteFolderSuccess');
    notification['success']({
      message: translation('success'),
      description: message,
      duration: 3,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: translation('error'),
    description: message,
    duration: 5,
  });
};

const showDeleteFormToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('deleteFormSuccess');
    notification['success']({
      message: translation('success'),
      description: message,
      duration: 3,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: translation('error'),
    description: message,
    duration: 5,
  });
};

const showDeleteSectionToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('deleteSectionSuccess');
    notification['success']({
      message: translation('success'),
      description: message,
      duration: 3,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: translation('error'),
    description: message,
    duration: 5,
  });
};

const showCommonErrorToastHandler = (action: Action) => {
  const { error, translation } = action;
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: 'error',
    description: message,
    duration: 5,
  });
};

const showUpdateFolderToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('updateFolderSuccess');
    notification['success']({
      message: translation('success'),
      description: message,
      duration: 3,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: translation('error'),
    description: message,
    duration: 5,
  });
};

const showUpdateFormInformationToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('updateFormSuccess');
    notification['success']({
      message: translation('success'),
      description: message,
      duration: 3,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: translation('error'),
    description: message,
    duration: 5,
  });
};
