import {
  Assignment,
  AssignmentProps,
  Attendee,
  AttendeeSubmission,
  EssayAnswer,
  FillInBlankGroupAnswer,
  MatchingAnswerGroup,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
} from '@module/assignment';
import { RootReducerState } from '../root.reducer';
import { GradingSubmissionStatus } from './grading-submission.reducer';
import { FormRelease, QuestionRelease, QuestionReleaseProps } from '@module/form';
import { RequestStatus } from '@lib/plugin-redux-core';

// Attendee List
export const attendeeResponsesSelector = (state: RootReducerState): Attendee[] =>
  state.gradingSubmission.attendeeList;

export const getAttendeeListStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.getAttendeeListStatus;

// Attendee By Id
export const attendeeSelector = (state: RootReducerState): Attendee =>
  state.gradingSubmission.attendee;

export const getAttendeeByIdStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.getAttendeeByIdStatus;

// Form Release
export const formReleaseSelector = (state: RootReducerState): FormRelease =>
  state.gradingSubmission.formRelease;

export const getFormReleaseByIdStatusSelector = (
  state: RootReducerState,
): GradingSubmissionStatus => state.gradingSubmission.getFormReleaseByIdStatus;

// Assignment
export const assignmentSelector = (state: RootReducerState): Assignment<AssignmentProps> =>
  state.gradingSubmission.assignment;

export const getAssignmentByIdStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.getAssignmentByIdStatus;

// Submission By Id
export const submissionSelector = (state: RootReducerState): SubmissionSummary =>
  state.gradingSubmission.submission;

export const updateSubmissionStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.updateSubmissionStatus;

export const getSubmissionByIdStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.getSubmissionByIdStatus;

// Submissions By Attendee
export const getSubmissionStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.getAttendeeSubmissionStatus;

export const attendeeSubmissionResponseSelector = (state: RootReducerState): AttendeeSubmission =>
  state.gradingSubmission.attendeeSubmission;

// Question Release
export const questionReleasesSelector = (
  state: RootReducerState,
): QuestionRelease<QuestionReleaseProps>[] => state.gradingSubmission.questionReleases;
export const maxScoreSelector = (state: RootReducerState): number =>
  state.gradingSubmission.maxScore;

export const getQuestionReleasesStatusSelector = (
  state: RootReducerState,
): GradingSubmissionStatus => state.gradingSubmission.getQuestionReleasesStatus;

// Answer
export const submissionAnswersSelector = (
  state: RootReducerState,
): (
  | EssayAnswer
  | SingleChoiceAnswer
  | FillInBlankGroupAnswer
  | MultipleChoiceAnswer
  | MatchingAnswerGroup
)[] => state.gradingSubmission.answers;

export const totalScoreSelector = (state: RootReducerState): number =>
  state.gradingSubmission.totalScore;

export const getSubmissionAnswersStatusSelector = (
  state: RootReducerState,
): GradingSubmissionStatus => state.gradingSubmission.getAnswersStatus;

export const updateAnswerStatusSelector = (state: RootReducerState): RequestStatus =>
  state.gradingSubmission.updateAnswerStatus;

export const updatedAnswerIdSelector = (state: RootReducerState): string =>
  state.gradingSubmission.updatedAnswerId;

export const gradingSubmissionStatusSelector = (state: RootReducerState): GradingSubmissionStatus =>
  state.gradingSubmission.gradingSubmissionStatus;
