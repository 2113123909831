import { FormRelease, QuestionRelease, QuestionReleaseProps, SectionSummary } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';

export const formReleaseSelector = (state: RootReducerState): FormRelease =>
  state.assignmentReview.formReleaseResponse;

export const loadingFormReleaseSelector = (state: RootReducerState): boolean =>
  state.assignmentReview.isLoadingFindForm;

export const selectedSectionSelector = (state: RootReducerState): SectionSummary =>
  state.assignmentReview.selectedSection;

export const selectedSectionIndexSelector = (state: RootReducerState): number =>
  state.assignmentReview.selectedSectionIndex;

export const sectionSummariesSelector = (state: RootReducerState): SectionSummary[] =>
  state.assignmentReview.sectionSummaries;

export const activeKeySelector = (state: RootReducerState): number[] =>
  state.assignmentReview.activeKey;

export const questionsSelector = (
  state: RootReducerState,
): QuestionRelease<QuestionReleaseProps>[] => state.assignmentReview.questions;

export const loadingQuestionsSelector = (state: RootReducerState): boolean =>
  state.assignmentReview.isLoadingFindQuestions;
