import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  AssignmentConfigurations,
  AssignmentSummary,
  DELETE_ASSIGNMENT_USE_CASE,
  GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
  GET_ASSIGNMENTS_USE_CASE,
  GET_SECURITY_OPTIONS_USE_CASE,
  SecurityOption,
} from '@module/assignment';
import { FormEditPageState } from '../form-edit.states';
import {
  DeleteAssignmentUseCaseState,
  GetAssignmentConfigurationUseCaseState,
  GetAssignmentsUseCaseState,
  GetSecurityOptionsUseCaseState,
} from './tab-assignment.states';

export const handleGetAssignmentsUseCaseState: UseCaseStateGenerator<GetAssignmentsUseCaseState> = {
  name: GET_ASSIGNMENTS_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      isLoadingGetAssignments: true,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { payload } = action;

    return {
      ...state,
      assignmentResponses: payload as AssignmentSummary[],
      isLoadingGetAssignments: false,
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      isLoadingGetAssignments: false,
    };
  },
};

export const handleGetSecurityOptionsUseCaseState: UseCaseStateGenerator<GetSecurityOptionsUseCaseState> =
  {
    name: GET_SECURITY_OPTIONS_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        getSecurityOptionsStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;

      return {
        ...state,
        securityOptions: payload as SecurityOption[],
        getSecurityOptionsStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        getSecurityOptionsStatus: RequestStatus.ERROR,
        error: error,
      };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        getSecurityOptionsStatus: RequestStatus.RESET,
      };
    },
  };

export const handleGetAssignmentConfigurationsUseCase: UseCaseStateGenerator<GetAssignmentConfigurationUseCaseState> =
  {
    name: GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return { ...state, isLoadingAssignmentDataConfigs: true };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const assignmentDataConfigs = payload as AssignmentConfigurations;
      return {
        ...state,
        assignmentConfigsResponse: assignmentDataConfigs,
        isLoadingAssignmentDataConfigs: false,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return { ...state, error: error, isLoadingAssignmentDataConfigs: false };
    },
  };

export const handleDeleteAssignmentUseCaseState: UseCaseStateGenerator<DeleteAssignmentUseCaseState> =
  {
    name: DELETE_ASSIGNMENT_USE_CASE,
    executing: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      return {
        ...state,
        deletedAssignmentId: payload as string,
        deleteAssignmentStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        deleteAssignmentStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return { ...state, error: error, deleteAssignmentStatus: RequestStatus.ERROR };
    },
    reset: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        deleteAssignmentStatus: RequestStatus.RESET,
      };
    },
  };

export const tabAssignmentHandlers = [
  handleGetAssignmentsUseCaseState,
  handleGetSecurityOptionsUseCaseState,
  handleGetAssignmentConfigurationsUseCase,
  handleDeleteAssignmentUseCaseState,
];
