import { Module } from '@nestjs/common';
import { authenticationUseCaseProviders } from './usecase-providers/authentication.provider';
import { authApiProvider } from './api-providers';
import { authenticationRepositoryProvider } from '@module/user';

@Module({
  providers: [authApiProvider, authenticationRepositoryProvider, ...authenticationUseCaseProviders],
  exports: [...authenticationUseCaseProviders],
})
export class AuthModule {}
