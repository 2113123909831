export var SubmissionStatusId;

(function (SubmissionStatusId) {
  SubmissionStatusId["ONGOING"] = "1";
  SubmissionStatusId["PAUSED"] = "2";
  SubmissionStatusId["SUBMITTED"] = "3";
  SubmissionStatusId["BANNED"] = "4";
})(SubmissionStatusId || (SubmissionStatusId = {}));

export var SubmissionStatusName;

(function (SubmissionStatusName) {
  SubmissionStatusName["ONGOING"] = "onGoing";
  SubmissionStatusName["PAUSED"] = "pause";
  SubmissionStatusName["SUBMITTED"] = "submitted";
  SubmissionStatusName["BANNED"] = "banned";
})(SubmissionStatusName || (SubmissionStatusName = {}));

export var submissionStatus = {
  ONGOING: {
    id: SubmissionStatusId.ONGOING,
    name: SubmissionStatusName.ONGOING
  },
  PAUSED: {
    id: SubmissionStatusId.PAUSED,
    name: SubmissionStatusName.PAUSED
  },
  SUBMITTED: {
    id: SubmissionStatusId.SUBMITTED,
    name: SubmissionStatusName.SUBMITTED
  }
};