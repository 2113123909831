import { Action } from '@lib/plugin-redux-core';
import {
  FormRelease,
  QuestionRelease,
  QuestionReleaseProps,
  QuestionReleases,
  SectionSummary,
} from '@module/form';
import { AssignmentReviewPageState } from './assignment-review.reducer';

export const handleFindSectionSummariesUseCase = {
  executing: (state: AssignmentReviewPageState): AssignmentReviewPageState => {
    return { ...state, isLoadSectionSummaries: true };
  },
  success: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { payload } = action;
    const sectionSummaries = payload as SectionSummary[];

    return {
      ...state,
      sectionSummaries,
      isLoadSectionSummaries: false,
    };
  },
  error: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadSectionSummaries: false,
    };
  },
};

export const handleFindFormReleaseByIdUseCase = {
  executing: (state: AssignmentReviewPageState): AssignmentReviewPageState => {
    return { ...state, isLoadingFindForm: true };
  },
  success: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { payload } = action;
    const formReleaseResponse = payload as FormRelease;
    const defaultSelectedSectionIndex = 0;

    return {
      ...state,
      formReleaseResponse,
      selectedSection: formReleaseResponse.sections[defaultSelectedSectionIndex],
      selectedSectionIndex: defaultSelectedSectionIndex,
      isLoadingFindForm: false,
    };
  },
  error: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingFindForm: false,
    };
  },
};

export const handleFindAllQuestionsReleaseUseCase = {
  executing: (state: AssignmentReviewPageState): AssignmentReviewPageState => {
    return { ...state, isLoadingFindQuestions: true };
  },
  success: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { payload } = action;
    const questions = payload as QuestionRelease<QuestionReleaseProps>[];
    const sortedQuestions = QuestionReleases.indexAllQuestions(questions);

    return {
      ...state,
      questions: sortedQuestions,
      isLoadingFindQuestions: false,
    };
  },
  error: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { error } = action;
    return {
      ...state,
      error,
      isLoadingFindQuestions: false,
    };
  },
};

export const handleChangeSelectedSection = {
  executing: (state: AssignmentReviewPageState, action: Action): AssignmentReviewPageState => {
    const { payload } = action;

    return {
      ...state,
      selectedSection: payload.selectedSection as SectionSummary,
      selectedSectionIndex: payload.selectedSectionIndex as number,
    };
  },
};
