import { File, Question, QuestionProps } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';
import { RequestStatus } from '@lib/plugin-redux-core';
import { CreatedFileStorage } from '@module/file-storage';

export const createdQuestionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.formEdit.createdQuestionStatus;
};
export const saveQuestionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.formEdit.saveQuestionStatus;
};
export const unsavedQuestionSelector = (state: RootReducerState): Question<QuestionProps> => {
  return state.formEdit.unsavedQuestion;
};

export const revertedQuestionSelector = (state: RootReducerState): Question<QuestionProps> => {
  return state.formEdit.revertedQuestion;
};
export const updateQuestionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.formEdit.updateQuestionStatus;
};
export const updateQuestionOrAnswerStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateQuestionOrAnswerStatus;
export const hasEssayOrFillInBlankQuestionSelector = (state: RootReducerState): boolean =>
  state.formEdit.hasEssayOrFillInBlankQuestion;
export const checkFormHasQuestionsSelector = (state: RootReducerState): boolean | null =>
  state.formEdit.isFormHasQuestions;
export const isQuestionEditingSelector = (state: RootReducerState): boolean =>
  state.formEdit.isQuestionEditing;
// local question
export const newQuestionSelector = (state: RootReducerState): Question<QuestionProps> => {
  return state.formEdit.newQuestion;
};
export const createQuestionFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createQuestionFileStatus;

export const createQuestionAnswerFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createQuestionAnswerFileStatus;
export const addQuestionFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.addQuestionFileStatus;
export const selectedFileSelector = (state: RootReducerState): CreatedFileStorage | File =>
  state.formEdit.selectedFile;
export const uploadingFileSelector = (state: RootReducerState): File =>
  state.formEdit.uploadingFile;
// type
export const updateQuestionTypeStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateQuestionTypeStatus;
// delete
export const deleteQuestionStatusSelector = (state: RootReducerState): RequestStatus => {
  return state.formEdit.deleteQuestionStatus;
};
// question in view
export const questionInViewSelector = (state: RootReducerState): string =>
  state.formEdit.questionInView;

export const updateQuestionAudioFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateQuestionAudioFileStatus;

export const addQuestionAudioFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.addQuestionAudioFileStatus;

export const deleteQuestionAudioFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.deleteQuestionAudioFileStatus;

export const cancelQuestionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.cancelQuestionStatus;
export * from './answer-item/answer-item.selectors';
