import {
  GET_SECURITY_OPTIONS_USE_CASE,
  GetSecurityOptionsUseCase,
  ISecurityOptionRepository,
  SECURITY_OPTION_REPOSITORY,
} from '@module/assignment';

export const securityOptionUseCaseProviders = [
  {
    provide: GET_SECURITY_OPTIONS_USE_CASE,
    useFactory: (securityOptionRepository: ISecurityOptionRepository) => {
      return new GetSecurityOptionsUseCase(securityOptionRepository);
    },
    inject: [SECURITY_OPTION_REPOSITORY],
  },
];
