import { RequestStatus, Section } from '@module/form';
import { RootReducerState } from '../../../../redux/root.reducer';
import { CreatedFileStorage } from '@module/file-storage';

export const findSelectedSectionSelector = (state: RootReducerState): Section =>
  state.formEdit.selectedSection;
export const sectionSelectedIndexSelector = (state: RootReducerState): number =>
  state.formEdit.selectedSectionIndex;
export const createSectionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createSectionStatus;
export const updateSectionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateSectionStatus;
export const deleteSectionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.deleteSectionStatus;
export const saveSectionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.saveSectionStatus;
export const revertedSectionSelector = (state: RootReducerState): Section =>
  state.formEdit.revertedSection;
export const editingSectionIdSelector = (state: RootReducerState): string =>
  state.formEdit.editingSectionId;
export const addSectionFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.addSectionFileStatus;
export const createSectionFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.createSectionFileStatus;
export const selectedSectionFileSelector = (state: RootReducerState): CreatedFileStorage | File =>
  state.formEdit.selectedSectionFile;
export const cancelSectionStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.cancelSectionStatus;

export const updateSectionAudioFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.updateSectionAudioFileStatus;

export const addSectionAudioFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.addSectionAudioFileStatus;

export const deleteSectionAudioFileStatusSelector = (state: RootReducerState): RequestStatus =>
  state.formEdit.deleteSectionAudioFileStatus;
