import { createReducer } from '@lib/plugin-redux-core';
import {
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
  FIND_QUESTION_RELEASES_USE_CASE,
  GET_SECTION_SUMMARIES_USE_CASE,
  FormRelease,
  SectionSummary,
  QuestionRelease,
  QuestionReleaseProps,
} from '@module/form';
import { UseCaseError } from '@digibricks/domains';
import { UPDATE_SELECTED_SECTION } from 'redux/root.action';
import {
  handleChangeSelectedSection,
  handleFindAllQuestionsReleaseUseCase,
  handleFindFormReleaseByIdUseCase,
  handleFindSectionSummariesUseCase,
} from './assignment-review.handler';

export type AssignmentReviewPageInitialState = {
  error?: UseCaseError<any>;
  activeKey: number[];
};

export type FindFormReleaseByIdUseCaseState = {
  formReleaseResponse: FormRelease;
  isLoadingFindForm: boolean;
};

export type FindSectionSummariesUseCaseState = {
  sectionSummaries: SectionSummary[];
  isLoadSectionSummaries: boolean;
};

export type FindAllQuestionUseCaseState = {
  questions: QuestionRelease<QuestionReleaseProps>[];
  isLoadingFindQuestions: boolean;
};

export type ChangeSelectedSection = {
  selectedSection: SectionSummary;
  selectedSectionIndex: number;
};

export type ChangeSectionPanelActiveKey = {};

export type AssignmentReviewPageState = AssignmentReviewPageInitialState &
  FindFormReleaseByIdUseCaseState &
  FindSectionSummariesUseCaseState &
  FindAllQuestionUseCaseState &
  ChangeSelectedSection &
  ChangeSectionPanelActiveKey;

const defaultActiveKey = 0;

export const initialState: AssignmentReviewPageInitialState = {
  error: null,
  activeKey: [defaultActiveKey],
};

export const assignmentReviewReducer = createReducer(initialState, [
  {
    name: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    ...handleFindFormReleaseByIdUseCase,
  },
  {
    name: FIND_QUESTION_RELEASES_USE_CASE,
    ...handleFindAllQuestionsReleaseUseCase,
  },
  {
    name: UPDATE_SELECTED_SECTION,
    ...handleChangeSelectedSection,
  },
  {
    name: GET_SECTION_SUMMARIES_USE_CASE,
    ...handleFindSectionSummariesUseCase,
  },
]);
