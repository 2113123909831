import { GET_SUBMISSIONS_USE_CASE, SubmissionSummary } from '@module/assignment';
import { createReducer } from '@lib/plugin-redux-core';
import { handleGetSubmissionUseCase } from './submission.handler';
import { UseCaseError } from '@digibricks/domains';

export type SubmissionInitialState = {
  error?: UseCaseError<any>;
  effect: number;
};
export type GetSubmissionsUseCaseState = {
  submissionResponse: SubmissionSummary[];
  isLoading: boolean;
  status: number;
};

export type SubmissionState = SubmissionInitialState & GetSubmissionsUseCaseState;

const initialState: SubmissionInitialState = {
  error: null,
  effect: 0,
};

export const submissionReducer = createReducer(initialState, [
  {
    name: GET_SUBMISSIONS_USE_CASE,
    ...handleGetSubmissionUseCase,
  },
]);
