import { createReducer } from '@lib/plugin-redux-core';
import { FormEditPageInitialState } from './form-edit.states';
import { commonFormHandlers } from './form-edit.handlers';
import { tabInfoHandlers } from './tab-info';
import { tabAssignmentHandlers } from './tab-assignment';
import { tabQuestionsHandlers } from './tab-questions';
import { formHeaderHandlers } from './header';

export const initialState: FormEditPageInitialState = {
  error: null,
  questions: [],
  imagesInQuestion: [],
  isQuestionEditing: false,
  // File
  selectedFile: null,
  isFormHasQuestions: null,
  hasEssayOrFillInBlankQuestion: false,
};

export const formEditReducer = createReducer(initialState, [
  ...commonFormHandlers,
  ...formHeaderHandlers,
  ...tabQuestionsHandlers,
  ...tabInfoHandlers,
  ...tabAssignmentHandlers,
]);
