import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import { FIND_FORM_BY_ID_USE_CASE } from '@module/form';
import {
  FindFormByIdUseCaseState,
  FormEditPageState,
  GetAssignmentConfigurationUseCaseState,
} from './form-edit.states';
import {
  AssignmentConfigurations,
  GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
} from '@module/assignment';

export const handleStateOfFindFormByIdUseCase: UseCaseStateGenerator<FindFormByIdUseCaseState> = {
  name: FIND_FORM_BY_ID_USE_CASE,
  executing: (state: FormEditPageState): FormEditPageState => {
    return {
      ...state,
      findFormByIdStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: FormEditPageState, action: Action): FormEditPageState => {
    const sections = action.payload.sections;
    let selectedFormItemId: string;
    if (sections && sections.length > 0) {
      selectedFormItemId = sections[0].id.toString();
    }
    return {
      ...state,
      formResponse: action.payload,
      sections,
      selectedFormItemId,
      findFormByIdStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: FormEditPageState, action: Action): FormEditPageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      findFormByIdStatus: RequestStatus.ERROR,
    };
  },
};
const filterConfigs = (data: AssignmentConfigurations): AssignmentConfigurations => {
  const filteredConfigs = {
    ...data,
  } as AssignmentConfigurations;

  return filteredConfigs;
};
export const handleGetAssignmentConfigurationsUseCase: UseCaseStateGenerator<GetAssignmentConfigurationUseCaseState> =
  {
    name: GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return { ...state };
    },
    success: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { payload } = action;
      const assignmentDataConfigs = filterConfigs(payload);
      return {
        ...state,
        assignmentConfigsResponse: assignmentDataConfigs,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return { ...state, error: error };
    },
  };

export const commonFormHandlers = [
  handleStateOfFindFormByIdUseCase,
  handleGetAssignmentConfigurationsUseCase,
];
