export var AssignmentStatus;

(function (AssignmentStatus) {
  AssignmentStatus["ACTIVE"] = "3";
  AssignmentStatus["PUBLISHED_SCORE"] = "5";
  AssignmentStatus["UPCOMING"] = "6";
  AssignmentStatus["ONGOING"] = "7";
  AssignmentStatus["ENDED"] = "8";
  AssignmentStatus["PASS"] = "10";
  AssignmentStatus["FAIL"] = "11";
  AssignmentStatus["GRADING"] = "12";
})(AssignmentStatus || (AssignmentStatus = {}));