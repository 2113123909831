import { createReducer } from '@lib/plugin-redux-core';
import { GET_USER_BY_ID_USE_CASE, SIGN_OUT_USE_CASE, User } from '@module/user';
import { UseCaseError } from '@digibricks/domains';
import { handleSignOutUseCase } from './layout.handler';
import { handleGetUserByIdUseCase } from './layout.handler';

export const enum PageLayoutStatus {
  'EXECUTE' = 1,
  'SUCCESS' = 2,
  'ERROR' = 3,
  'RESET' = 4,
}

export type PageLayoutInitialState = {
  error?: UseCaseError<any>;
};

export type GetUserByIdUseCaseType = {
  userResponse: User;
  getUserByIdStatus: PageLayoutStatus;
};

export type SignOutUseCaseType = {
  signOutStatus: PageLayoutStatus;
};

export type PageLayoutState = PageLayoutInitialState & GetUserByIdUseCaseType & SignOutUseCaseType;

const initialState: PageLayoutInitialState = {
  error: null,
};

export const pageLayoutReducer = createReducer(initialState, [
  {
    name: SIGN_OUT_USE_CASE,
    ...handleSignOutUseCase,
  },
  {
    name: GET_USER_BY_ID_USE_CASE,
    ...handleGetUserByIdUseCase,
  },
]);
