import {
  GET_GENERAL_INFORMATION_REPORT_USE_CASE,
  GET_QUESTION_REPORTS_USE_CASE,
  GetGeneralInformationReportUseCase,
  GetQuestionReportsUseCase,
  IReportRepository,
  REPORT_REPOSITORY,
  UPDATE_QUESTION_REPORT_BOOKMARK_USE_CASE,
  UpdateQuestionReportBookmarkUseCase,
} from '@module/report';

export const reportUseCaseProviders = [
  {
    provide: GET_GENERAL_INFORMATION_REPORT_USE_CASE,
    useFactory: (reportRepository: IReportRepository) => {
      return new GetGeneralInformationReportUseCase(reportRepository);
    },
    inject: [REPORT_REPOSITORY],
  },
  {
    provide: GET_QUESTION_REPORTS_USE_CASE,
    useFactory: (reportRepository: IReportRepository) => {
      return new GetQuestionReportsUseCase(reportRepository);
    },
    inject: [REPORT_REPOSITORY],
  },
  {
    provide: UPDATE_QUESTION_REPORT_BOOKMARK_USE_CASE,
    useFactory: (reportRepository: IReportRepository) => {
      return new UpdateQuestionReportBookmarkUseCase(reportRepository);
    },
    inject: [REPORT_REPOSITORY],
  },
];
