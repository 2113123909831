import {
  FIND_QUESTION_RELEASES_USE_CASE,
  FindQuestionReleasesUseCase,
  IQuestionReleasesRepository,
  QUESTION_RELEASES_REPOSITORY,
} from '@module/form';

export const questionReleaseUseCaseProviders = [
  {
    provide: FIND_QUESTION_RELEASES_USE_CASE,
    useFactory: (questionReleasesRepository: IQuestionReleasesRepository) => {
      return new FindQuestionReleasesUseCase(questionReleasesRepository);
    },
    inject: [QUESTION_RELEASES_REPOSITORY],
  },
];
