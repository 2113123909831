import { createReducer } from '@lib/plugin-redux-core';
import { formPreviewHandlers } from './form-preview.handler';
import { FormPreviewPageInitialState } from './form-preview.states';

const defaultActiveKey = 0;

export const initialState: FormPreviewPageInitialState = {
  error: null,
  activeKey: [defaultActiveKey],
};

export const formPreviewReducer = createReducer(initialState, [...formPreviewHandlers]);
