import { GeneralInformationReport, QuestionReport, QuestionReportProps } from '@module/report';
import { RootReducerState } from '../root.reducer';

export const loadingGetInformationReport = (state: RootReducerState): boolean =>
  state.report.isLoadingGetGeneralInformationReport;

export const generalInformationReportResponseSelector = (
  state: RootReducerState,
): GeneralInformationReport => state.report.generalInformationReportResponse;

export const getGeneralInformationReportStatusSelector = (state: RootReducerState): number =>
  state.report.getGeneralInformationReportStatus;

export const loadingGetQuestionReportsSelector = (state: RootReducerState): boolean =>
  state.report.isLoadingGetQuestionReports;

export const questionReportsResponseSelector = (
  state: RootReducerState,
): QuestionReport<QuestionReportProps>[] => state.report.questionReportsResponse;

export const bookmarkedQuestionReportsResponseSelector = (
  state: RootReducerState,
): QuestionReport<QuestionReportProps>[] => state.report.bookmarkedQuestionReports;

export const getQuestionReportsStatusSelector = (state: RootReducerState): number =>
  state.report.getQuestionReportsStatus;

export const loadingUpdateQuestionReportBookmarkSelector = (state: RootReducerState): boolean =>
  state.report.isLoadingUpdateQuestionReportBookmark;

export const updateQuestionReportBookmarkStatusSelector = (state: RootReducerState): number =>
  state.report.updateQuestionReportBookmarkStatus;
