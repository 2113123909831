import {
  SubmissionWebCached,
  SUBMISSION_WEB_CACHED,
  ASSIGNMENT_CONFIGURATION_CACHED,
  AssignmentConfigurationCached,
  ESSAY_ANSWER_DRAFT_WEB_CACHED,
  EssayAnswerDraftWebCached,
  AssignmentInMemoryWebCached,
  ASSIGNMENT_CACHED_REPOSITORY,
} from '@module/assignment';
import {
  GRADE_WEB_CACHED,
  GradeWebCached,
  SUBJECT_WEB_CACHED,
  SubjectWebCached,
  FormReleaseInMemoryWebCached,
  FORM_RELEASE_CACHED_REPOSITORY,
  QUESTION_RELEASE_CACHED_REPOSITORY,
  QuestionReleaseInMemoryCached,
} from '@module/form';

export const cachedProviders = [
  {
    provide: GRADE_WEB_CACHED,
    useFactory: () => {
      return new GradeWebCached();
    },
  },
  {
    provide: SUBJECT_WEB_CACHED,
    useFactory: () => {
      return new SubjectWebCached();
    },
  },
  {
    provide: SUBMISSION_WEB_CACHED,
    useFactory: () => {
      return new SubmissionWebCached();
    },
  },
  {
    provide: ASSIGNMENT_CACHED_REPOSITORY,
    useFactory: () => {
      return new AssignmentInMemoryWebCached();
    },
  },
  {
    provide: QUESTION_RELEASE_CACHED_REPOSITORY,
    useFactory: () => {
      return new QuestionReleaseInMemoryCached();
    },
  },
  {
    provide: FORM_RELEASE_CACHED_REPOSITORY,
    useFactory: () => {
      return new FormReleaseInMemoryWebCached();
    },
  },
  {
    provide: ASSIGNMENT_CONFIGURATION_CACHED,
    useFactory: () => {
      return new AssignmentConfigurationCached();
    },
  },
  {
    provide: ESSAY_ANSWER_DRAFT_WEB_CACHED,
    useFactory: () => {
      return new EssayAnswerDraftWebCached();
    },
  },
];
