import {
  CREATE_SECTION_FILE_STORAGE_USE_CASE,
  CreateSectionFileUseCase,
  EXTERNAL_FILE_STORAGE_REPOSITORY,
  FILE_STORAGE_REPOSITORY,
  IExternalFileStorageRepository,
  IFileStorageRepository,
} from '@module/file-storage';
import {
  IFormRepository,
  CreateFormUseCase,
  FORM_REPOSITORY,
  UpdateFormUseCase,
  FindFormByIdUseCase,
  DeleteFormUseCase,
  CreateSectionUseCase,
  DeleteSectionUseCase,
  UpdateSectionUseCase,
  GetSectionSummariesUseCase,
  FIND_FORM_BY_ID_USE_CASE,
  CREATE_FORM_USE_CASE,
  UPDATE_FORM_INFORMATION_USE_CASE,
  DELETE_FORM_USE_CASE,
  CREATE_SECTION_USE_CASE,
  UPDATE_SECTION_USE_CASE,
  DELETE_SECTION_USE_CASE,
  GET_SECTION_SUMMARIES_USE_CASE,
  UPDATE_FORM_TITLE_USE_CASE,
  IMPORT_FORM_USE_CASE,
  ImportFormUseCase,
  FIND_FORM_BY_IDS_USE_CASE,
  FindFormByIdsUseCase,
  SAVE_SECTION_USE_CASE,
  SaveSectionUseCase,
  CANCEL_SECTION_USE_CASE,
  CancelSectionUseCase,
  ADD_SECTION_FILE_USE_CASE,
  AddSectionFileUseCase,
  DELETE_SECTION_FILE_USE_CASE,
  DeleteSectionFileUseCase,
  ADD_SECTION_AUDIO_FILE_USE_CASE,
  AddSectionAudioFileUseCase,
  DELETE_SECTION_AUDIO_FILE_USE_CASE,
  DeleteSectionAudioFileUseCase,
  UpdateSectionAudioFileUseCase,
  UPDATE_SECTION_AUDIO_FILE_USE_CASE,
} from '@module/form';
import { Provider } from '@nestjs/common';

export const formUseCaseProviders: Partial<Provider>[] = [
  {
    provide: FIND_FORM_BY_ID_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new FindFormByIdUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: FIND_FORM_BY_IDS_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new FindFormByIdsUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CREATE_FORM_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new CreateFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: IMPORT_FORM_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new ImportFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: UPDATE_FORM_TITLE_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new UpdateFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: UPDATE_FORM_INFORMATION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new UpdateFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: DELETE_FORM_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new DeleteFormUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CREATE_SECTION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new CreateSectionUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: UPDATE_SECTION_USE_CASE,
    useFactory: () => {
      return new UpdateSectionUseCase();
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: SAVE_SECTION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new SaveSectionUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: DELETE_SECTION_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new DeleteSectionUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CANCEL_SECTION_USE_CASE,
    useValue: new CancelSectionUseCase(),
  },
  {
    provide: GET_SECTION_SUMMARIES_USE_CASE,
    useFactory: (formRepository: IFormRepository) => {
      return new GetSectionSummariesUseCase(formRepository);
    },
    inject: [FORM_REPOSITORY],
  },
  {
    provide: CREATE_SECTION_FILE_STORAGE_USE_CASE,
    useFactory: (
      fileStorageRepository: IFileStorageRepository,
      externalFileStorageRepository: IExternalFileStorageRepository,
    ) => {
      return new CreateSectionFileUseCase(fileStorageRepository, externalFileStorageRepository);
    },
    inject: [FILE_STORAGE_REPOSITORY, EXTERNAL_FILE_STORAGE_REPOSITORY],
  },
  {
    provide: DELETE_SECTION_FILE_USE_CASE,
    useClass: DeleteSectionFileUseCase,
  },
  {
    provide: ADD_SECTION_FILE_USE_CASE,
    useClass: AddSectionFileUseCase,
  },
  {
    provide: ADD_SECTION_AUDIO_FILE_USE_CASE,
    useValue: new AddSectionAudioFileUseCase(),
  },
  {
    provide: DELETE_SECTION_AUDIO_FILE_USE_CASE,
    useValue: new DeleteSectionAudioFileUseCase(),
  },
  {
    provide: UPDATE_SECTION_AUDIO_FILE_USE_CASE,
    useValue: new UpdateSectionAudioFileUseCase(),
  },
];
