import { AssignmentConfigurations } from '@module/assignment';
import { Form } from '@module/form';
import { RootReducerState } from 'redux/root.reducer';

export const findFormByIdSelector = (state: RootReducerState): Form => state.formEdit.formResponse;

export const assignmentConfigsResponseSelector = (
  state: RootReducerState,
): AssignmentConfigurations => state.formEdit.assignmentConfigsResponse;

export * from './tab-questions/tab-questions.selectors';
export * from './tab-info/tab-info.selectors';
export * from './tab-assignment/tab-assignment.selectors';
