import {
  ANSWER_COMMENT_USE_CASE,
  Assignment,
  AssignmentProps,
  Attendee,
  AttendeeSubmission,
  EssayAnswer,
  FillInBlankGroupAnswer,
  GET_ANSWERS_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_ATTENDEES_USE_CASE,
  GET_ATTENDEE_BY_ID_USE_CASE,
  GET_ATTENDEE_SUBMISSIONS_USE_CASE,
  GET_SUBMISSION_BY_ID_USE_CASE,
  GRADING_ANSWER_USE_CASE,
  GRADING_SUBMISSION_USE_CASE,
  MatchingAnswerGroup,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  SubmissionSummary,
  UPDATE_SUBMISSION_USE_CASE,
} from '@module/assignment';
import { createReducer, RequestStatus } from '@lib/plugin-redux-core';
import { UseCaseError } from '@digibricks/domains';
import {
  handleAnswerCommentUseCase,
  handleGetAnswersUseCase,
  handleGetAssignmentByIdUseCase,
  handleGetAttendeeByIdUseCase,
  handleGetAttendeeSubmissionUseCase,
  handleGetAttendeesUseCase,
  handleGetFormReleaseByIdUseCase,
  handleGetQuestionReleasesUseCase,
  handleGetSubmissionById,
  handleGradingAnswersUseCase,
  handleGradingSubmissionUseCase,
  handleUpdateAttendeeGradedStateUseCase,
  handleUpdateSubmissionUseCase,
} from './grading-submission.handler';
import {
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
  FIND_QUESTION_RELEASES_USE_CASE,
  FormRelease,
  QuestionRelease,
  QuestionReleaseProps,
} from '@module/form';

export const enum GradingSubmissionStatus {
  'EXECUTE' = 1,
  'SUCCESS' = 2,
  'ERROR' = 3,
  'RESET' = 4,
}

export type GradingSubmissionInitialState = {
  error?: UseCaseError<any>;
};
export type GetAttendeesUseCaseState = {
  attendeeList: Attendee[];
  getAttendeeListStatus: GradingSubmissionStatus;
};

export type GetAttendeeByIdUseCaseState = {
  attendee: Attendee;
  getAttendeeByIdStatus: GradingSubmissionStatus;
};

export type GetFormReleaseByIdUseCaseState = {
  formRelease: FormRelease;
  getFormReleaseByIdStatus: GradingSubmissionStatus;
};

export type GetAssignmentByIdUseCaseState = {
  assignment: Assignment<AssignmentProps>;
  getAssignmentByIdStatus: GradingSubmissionStatus;
};

export type GetSubmissionByIdUseCaseState = {
  submission: SubmissionSummary;
  getSubmissionByIdStatus: GradingSubmissionStatus;
};

export type GetQuestionReleasesUseCaseState = {
  questionReleases: QuestionRelease<QuestionReleaseProps>[];
  getQuestionReleasesStatus: GradingSubmissionStatus;
  maxScore: number;
};

export type GetSubmissionAnswersUseCaseState = {
  answers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[];
  getAnswersStatus: GradingSubmissionStatus;
  totalScore: number;
};

export type GetAttendeeSubmissionUseCaseState = {
  attendeeSubmission: AttendeeSubmission;
  getAttendeeSubmissionStatus: GradingSubmissionStatus;
};

export type UpdateAnswerUseCaseState = {
  updatedAnswerId: string;
  updateAnswerStatus: RequestStatus;
};

export type UpdateAttendeeGradedStateUseCaseState = {
  updateAttendeeGradedStateStatus: GradingSubmissionStatus;
};

export type UpdateSubmissionUseCaseState = {
  updateSubmissionStatus: GradingSubmissionStatus;
};

export type AutoGradingUseCaseState = {
  gradingSubmissionStatus: GradingSubmissionStatus;
};

export type GradingSubmissionState = GradingSubmissionInitialState &
  AutoGradingUseCaseState &
  GetAttendeesUseCaseState &
  GetAttendeeByIdUseCaseState &
  GetFormReleaseByIdUseCaseState &
  GetAssignmentByIdUseCaseState &
  GetSubmissionByIdUseCaseState &
  GetQuestionReleasesUseCaseState &
  GetSubmissionAnswersUseCaseState &
  GetAttendeeSubmissionUseCaseState &
  UpdateAnswerUseCaseState &
  UpdateSubmissionUseCaseState &
  UpdateAttendeeGradedStateUseCaseState;

const initialState: GradingSubmissionInitialState = {
  error: null,
};

export const gradingSubmissionReducer = createReducer(initialState, [
  {
    name: GET_ATTENDEES_USE_CASE,
    ...handleGetAttendeesUseCase,
  },
  {
    name: GET_ATTENDEE_BY_ID_USE_CASE,
    ...handleGetAttendeeByIdUseCase,
  },
  {
    name: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    ...handleGetFormReleaseByIdUseCase,
  },
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    ...handleGetAssignmentByIdUseCase,
  },
  {
    name: GET_SUBMISSION_BY_ID_USE_CASE,
    ...handleGetSubmissionById,
  },
  {
    name: FIND_QUESTION_RELEASES_USE_CASE,
    ...handleGetQuestionReleasesUseCase,
  },
  {
    name: GET_ANSWERS_USE_CASE,
    ...handleGetAnswersUseCase,
  },
  {
    name: GET_ATTENDEE_SUBMISSIONS_USE_CASE,
    ...handleGetAttendeeSubmissionUseCase,
  },
  {
    name: GRADING_ANSWER_USE_CASE,
    ...handleGradingAnswersUseCase,
  },
  {
    name: ANSWER_COMMENT_USE_CASE,
    ...handleAnswerCommentUseCase,
  },
  {
    name: UPDATE_SUBMISSION_USE_CASE,
    ...handleUpdateSubmissionUseCase,
  },
  {
    name: GRADING_SUBMISSION_USE_CASE,
    ...handleGradingSubmissionUseCase,
  },
]);
