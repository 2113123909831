import {
  ASSIGNMENT_REPOSITORY,
  IAssignmentRepository,
  GetAssignmentsUseCase,
  GetAssignmentByIdUseCase,
  GetAttendeesUseCase,
  DeleteAssignmentUseCase,
  GET_ASSIGNMENTS_USE_CASE,
  DELETE_ASSIGNMENT_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_ATTENDEES_USE_CASE,
  CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
  CreatePracticeAssignmentUseCase,
  GET_ATTENDEE_BY_ID_USE_CASE,
  GetAttendeeByIdUseCase,
  IPracticeAssignmentRepository,
  PRACTICE_ASSIGNMENT_REPOSITORY,
  CREATE_HOMEWORK_ASSIGNMENT_USE_CASE,
  IHomeworkAssignmentRepository,
  HOMEWORK_ASSIGNMENT_REPOSITORY,
  CreateHomeworkAssignmentUseCase,
  PUBLISH_SCORE_USE_CASE,
  PublishScoreUseCase,
  CREATE_EXAMINATION_ASSIGNMENT_USE_CASE,
  EXAMINATION_ASSIGNMENT_REPOSITORY,
  CreateExaminationAssignmentUseCase,
  IExaminationAssignmentRepository,
} from '@module/assignment';
import { FORM_RELEASE_REPOSITORY, IFormReleaseRepository } from '@module/form';

export const assignmentUseCaseProviders = [
  {
    provide: GET_ASSIGNMENT_BY_ID_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAssignmentByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ASSIGNMENTS_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAssignmentsUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: DELETE_ASSIGNMENT_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new DeleteAssignmentUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: GET_ATTENDEES_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAttendeesUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  // Practice assignment
  {
    provide: CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
    useFactory: (
      practiceAssignmentRepository: IPracticeAssignmentRepository,
      formReleaseRepository: IFormReleaseRepository,
    ) => {
      return new CreatePracticeAssignmentUseCase(
        practiceAssignmentRepository,
        formReleaseRepository,
      );
    },
    inject: [PRACTICE_ASSIGNMENT_REPOSITORY, FORM_RELEASE_REPOSITORY],
  },
  // Homework assignment
  {
    provide: CREATE_HOMEWORK_ASSIGNMENT_USE_CASE,
    useFactory: (
      homeworkAssignmentRepository: IHomeworkAssignmentRepository,
      formReleaseRepository: IFormReleaseRepository,
    ) => {
      return new CreateHomeworkAssignmentUseCase(
        homeworkAssignmentRepository,
        formReleaseRepository,
      );
    },
    inject: [HOMEWORK_ASSIGNMENT_REPOSITORY, FORM_RELEASE_REPOSITORY],
  },
  // Examination assignment
  {
    provide: CREATE_EXAMINATION_ASSIGNMENT_USE_CASE,
    useFactory: (
      examinationAssignmentRepository: IExaminationAssignmentRepository,
      formReleaseRepository: IFormReleaseRepository,
    ) => {
      return new CreateExaminationAssignmentUseCase(
        examinationAssignmentRepository,
        formReleaseRepository,
      );
    },
    inject: [EXAMINATION_ASSIGNMENT_REPOSITORY, FORM_RELEASE_REPOSITORY],
  },
  {
    provide: GET_ATTENDEE_BY_ID_USE_CASE,
    useFactory: (assignmentRepository: IAssignmentRepository) => {
      return new GetAttendeeByIdUseCase(assignmentRepository);
    },
    inject: [ASSIGNMENT_REPOSITORY],
  },
  {
    provide: PUBLISH_SCORE_USE_CASE,
    useFactory: (homeworkAssignmentRepository: IHomeworkAssignmentRepository) => {
      return new PublishScoreUseCase(homeworkAssignmentRepository);
    },
    inject: [HOMEWORK_ASSIGNMENT_REPOSITORY],
  },
];
