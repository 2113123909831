import { LoadingPage, NetworkErrorPage } from '@lib/common';
import 'antd/dist/antd.css';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import App, { AppProps, AppContext } from 'next/app';
import Head from 'next/head';
import { withRouter } from 'next/router';
import React, { ReactNode } from 'react';
import { runAsyncEpics, runAuthAsyncEpics } from '../redux/root.epic';
import { wrapper } from '../redux/store';
import { Analytics } from '@vercel/analytics/react';
import getConfig from 'next/config';
import { Token } from '@module/user';

// Global CSS
import 'katex/dist/katex.min.css';
import 'mathquill/build/mathquill.css';
import '@module/form-components/src/rich-text-editor/math4quill.css';
import 'react-quill/dist/quill.snow.css';

interface State {
  isOnline: boolean;
}
type Page<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type Props = AppProps & {
  Component: Page;
};
class MyApp extends App<Props, {}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOnline: true,
    };
    this.getLayout = this.props.Component.getLayout ?? ((page: ReactNode) => page);
  }
  public getLayout;

  public static getInitialProps = wrapper.getInitialAppProps(() => async (context) => {
    return {
      pageProps: {
        ...(await App.getInitialProps(context)).pageProps,
      },
    };
  });

  async componentDidMount() {
    const isRunningClientSide = process.browser;
    const { publicRuntimeConfig } = getConfig();
    const { router } = this.props;
    const accessToken = localStorage.getItem('access_token');
    const redirectUrl = `${router.asPath}`;
    const signInUrl = `${publicRuntimeConfig.app.authentication.page.signIn.url}&redirectUrl=${redirectUrl}`;
    if (isRunningClientSide) {
      const shouldRedirect = () => {
        if (accessToken) {
          return Token.isTokenExpired(accessToken) && !router.asPath.includes('callback');
        } else {
          return !router.asPath.includes('callback');
        }
      };

      if (shouldRedirect()) {
        window.location.href = signInUrl;
      } else {
        const shouldRunAsyncEpics = accessToken && !Token.isTokenExpired(accessToken);
        const shouldRunAuthAsyncEpics = !accessToken || router.asPath.includes('callback');
        if (shouldRunAsyncEpics) {
          await runAsyncEpics();
        }
        if (shouldRunAuthAsyncEpics) {
          await runAuthAsyncEpics();
        }
      }
    }
    this.setState({ isOnline: navigator.onLine });
  }

  render() {
    const { Component, pageProps } = this.props;
    const isRunningClientSide = process.browser;

    const renderPageDetail = () =>
      this.state.isOnline ? (
        <>
          <Head>
            <link rel="shortcut icon" href="/images/Favicon.svg" />
          </Head>
          {this.getLayout(<Component {...pageProps} />)}
          <Analytics />
        </>
      ) : (
        <NetworkErrorPage />
      );

    const renderPageOnBrowser = (): ReactNode =>
      isRunningClientSide ? renderPageDetail() : <LoadingPage />;

    return <>{renderPageOnBrowser()}</>;
  }
}
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: '/' });
    appContext.ctx.res.end();
    return;
  }

  return { ...appProps };
};

const MyAppTranslationWrapper = appWithTranslation(MyApp);
export default withRouter(wrapper.withRedux(MyAppTranslationWrapper));
