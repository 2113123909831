import { User } from '@module/user';
import { RootReducerState } from 'redux/root.reducer';

export const userSelector = (state: RootReducerState): User => state.pageLayout.userResponse;

export const signOutStatusSelector = (state: RootReducerState): number =>
  state.pageLayout.signOutStatus;

export const getUserByIdStatusSelector = (state: RootReducerState): number =>
  state.pageLayout.getUserByIdStatus;
